import AppHeader from '../header';
import AppFooter from '../footer';
import img1 from '../../../src/assets/images/Firefly a lot of people with desks working on algorithm and doing high performance work; professiona.jpg';
import animation from '../../../src/assets/images/animation.gif';
import React from 'react';

function AppAbout() {
  return (
    <>
      <header id="header">
        <AppHeader />
      </header>
      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
              <h1 className='text-white text-center font-bold pt-5 m-0'>What is CONTRA?</h1>
            </div>
          </section>
        </div>
      </div>

      <div className="content-container md:px-20">
        <div className="flex flex-col md:flex-row items-center md:justify-between py-8">
          <div className="md:w-1/2">
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
              CONTRA, an acronym for <em>Computing an Optimal Network of Transit Routes 
              through Mathematical Algorithms</em>, is our innovative computer program developed 
              specifically for strategic infrastructure planning.
            </p>
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
              With CONTRA, routes and networks can be calculated within a few hours or a few 
              days, depending on the workload. The relatively short planning time is due to the 
              advanced optimization methods and intelligent computer algorithms.
            </p>
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
              In case of road planning, our software can calculate and plan the construction of 
              new intermodal routes between two different locations in any country or across countries. 
              The routes can consist of different infrastructure segments, such as roads, railroads, 
              pipelines, tunnels, and bridges. For this purpose, the different GIS data are utilized to 
              analyze and evaluate the soil conditions, environmental conditions, and topographic elevation 
              data. The result of the software is a summary of the data of the calculated route, including 
              the route line, the route length, the elevation profile of the route, and the construction costs.
            </p>
          </div>
          <div className="md:w-1/2 flex justify-center md:justify-end mt-5 md:mt-0">
            <figure>
              <img
                src={animation}
                alt="optical fiber 2"
                className="w-full"
              />
            </figure>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center md:justify-between py-8 md:flex-row-reverse">
          <div className="md:w-1/2">
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
              In a further step, our software is capable of planning the routing of optimal energy networks and 
              supply lines in an area with an arbitrary number of households. Energy networks and supply lines 
              include pipelines, fiber optic networks, gas networks, electricity networks, and water pipelines. 
              The mentioned algorithms ensure a sustainable design of the laying of the energy networks and cost-efficient 
              planning of them. As an additional feature, our software can calculate the smallest networks in terms of 
              length to save material and energy and reduce the distance. This causes lower friction and pressure 
              reductions to decrease CO2 emissions.
            </p>
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
              On top of optimal route planning and determination of efficient energy networks, CONTRA can perform 
              optimal location planning for industries and factories. Numerous parameters are considered, ranging 
              from the amount of existing raw materials and resources to energy sources and existing infrastructure. 
              The market potential is also included in the calculation to select a location with the greatest possible 
              sales potential, while at the same time keeping transportation and supply costs to a minimum. As a result, 
              optimal site planning not only has a cost-effective effect but also a sustainable one, providing a strategic 
              advantage for our customers.
            </p>
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
              Powerful hardware and high-performance computers are used for effective calculations and designs. Our calculated 
              results are more cost-effective and precise for our customers and business partners than conventional methods.
            </p>
          </div>
          <div className="md:w-1/2 flex justify-center md:justify-start mt-5 md:mt-0">
            <figure>
              <img
                src={img1}
                alt="glassfiber 1"
                className="w-full max-w-xl"
              />
            </figure>
          </div>
        </div>
      </div>
      <div class="button-container mx-auto">
          <div class="flex justify-end">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/services" className='text-white'>
                      Our Services →
                  </a>
              </button>
          </div>
      </div>
      <AppFooter />
    </>
  );
}

export default AppAbout;
