import React from 'react';
import AppHeaderde from '../de_header';
import AppFooterde from '../de_footer';
import fawadImage from '../../../src/assets/images/fawad.jpeg'; // Import individual images for each team member
import fuegenschuhImage from '../../../src/assets/images/Fuegenschuh.jpg';
import mantwillImage from '../../../src/assets/images/mantwill.jpg';
import qaisZazaiImage from '../../../src/assets/images/Qais-Zazai.jpeg';
import roostaiImage from '../../../src/assets/images/Roostai.jpg';
import redlichImage from '../../../src/assets/images/Tobias-Redlich.jpg';
import akhtarImage from '../../../src/assets/images/Faizl-Akhtar.jpg';
import raofiImage from '../../../src/assets/images/Atik_Raofi.jpg';
import thiwaImage from '../../../src/assets/images/david-thiwa.jpg';
import taheraImage from '../../../src/assets/images/Tahera.jpg';
import ankitImage from '../../../src/assets/images/Ankit.jpg';

const teamsData = [
  {
    id: 1,
    image: fawadImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr. M.Fawad Zazai',
    designation: 'Chief Executive Officer & Gründer',
    description: 'Unser Vorstandsvorsitzender und Gründer verfügt über umfassende Erfahrung als Berater für internationale Transportunternehmen. Seine Fachkenntnisse liegen insbesondere in der Entwicklung von Algorithmen und Optimierungstechniken für komplexe wissenschaftliche Probleme im Bereich der Infrastruktur. Er ist der kreative Kopf hinter den Algorithmen und Methoden, die unsere Software CONTRA antreiben. Darüber hinaus zeichnet er sich durch die Schaffung innovativer Softwarelösungen für wissenschaftliche und praktische Herausforderungen aus. Sein Engagement erstreckt sich auch auf die Modellierung und Optimierung verschiedener Prozesse.',
  },
  {
    id: 2,
    image: qaisZazaiImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'M. Qais Zazai',
    designation: 'Chief Operating Officer & Business Development Manager',
    description: 'Unser Chief Operating Officer und Business Development Manager hat erfolgreich seinen MBA mit dem Schwerpunkt Nachhaltigkeit an der Universität Hamburg abgeschlossen und verfügt über umfassende Erfahrung als Gründer eines eigenen internationalen Start-up-Unternehmens. Sein Wissen als Sozialunternehmer bereichert unser Team maßgeblich und trägt zur strategischen Ausrichtung von CONTRA bei. Als versierter Marketingexperte repräsentiert er das Unternehmen nach außen und ist verantwortlich für die Konzeptentwicklung und deren erfolgreiche Umsetzung.',
  },
  {
    id: 3,
    image: mantwillImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Prof. Dr.-Ing. Frank Mantwill',
    designation: 'Projektleitung & Strategischer Berater',
    description: 'Unser Leiter für Projektmanagement und strategischer Berater ist Professor für Maschinenelemente und computergestützte Produktentwicklung an der Helmut-Schmidt-Universität in Hamburg. Mit seiner herausragenden Expertise auf dem Gebiet der Produkt- und Prozessentwicklung in der Industrie hat er eine führende Rolle eingenommen. Zuletzt bekleidete er die Position des Leiters der Produktionsplanung im Karosseriebau eines renommierten deutschen Auto-mobilherstellers im Premiumsegment. Seine Spezialisierung liegt insbesondere in der Produkt- und Prozessentwicklung. Als Projektleiter plant er unsere Projekte sorgfältig und leitet deren erfolgreiche Umsetzung. Darüber hinaus ist er maßgeblich an der Entwicklung der Unternehmensstrategien beteiligt.',
  },
  {
    id: 4,
    image: fuegenschuhImage, 
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Prof. Dr. Armin Fügenschuh',
    designation: 'Chief Technology Officer & Wissenschaftlicher Berater',
    description: 'Unser CTO und wissenschaftlicher Berater ist Professor für Ingenieurmathematik und Numerische Optimierung an der Technischen Hochschule Brandenburg in Deutschland. Seine Expertise war entscheidend bei der Implementierung von IT-Lösungen, insbesondere in Bezug auf die kommenden Anwendungsprogrammierschnittstellen. Er konzipiert und entwickelt innovative Algorithmen sowie Optimierungsmethoden, die für die erfolgreiche Umsetzung unserer Projekte von zentraler Bedeutung sind. Seine Fachkenntnisse erstrecken sich insbesondere auf das Gebiet der gemischt-ganzzahligen Optimierung, wobei er sich auf die Lösung anspruchsvoller und umfangreicher Probleme spezialisiert hat. Darüber hinaus trägt er zur Entwicklung von Lösungsmethoden und Konzepten für praktische Probleme bei.',
  },
  {
    id: 5,
    image: redlichImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr.-Ing. Tobias Redlich',
    designation: 'Ingenieur & Technischer Berater',
    description: 'Unser Ingenieur und Technischer Berater fungiert als wissenschaftlicher Berater und ist verantwortlich für technische und ingenieurwissenschaftliche Fragestellungen. Zudem bekleidet er die Position des Geschäftsführers des Hamburger Instituts für Wertschöpfungssystematik und Wissensmanagement (HIWW) sowie die Rolle des wissenschaftlichen Leiters des Labors für Fertigungstechnik (LaFT) an der Helmut-Schmidt-Universität in Hamburg.',
  },
  {
    id: 6,
    image: ankitImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Ankit Vaghasiya',
    designation: 'Softwareentwickler',
    description: 'Herr Ankit Vaghasiya absolvierte sein Studium in Physik an der Universität Hamburg. Parallel zu seiner akademischen Laufbahn tauchte er in die Welt der Webentwicklung und des Software-Engineerings ein. Hier erforschte er die faszinierende Schnittmenge von Wissenschaft und Technik, und brachte sein gewonnenes Wissen erfolgreich in unser Unternehmen ein.',
  },
/*   {
    id: 7,
    image: roostaiImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr. Abdul Hanan Roostai',
    designation: 'Geophysiker und Geologe',
    description: 'Unser Experte für Geophysik und Geologie verfügt über umfassende Erfahrung als Berater im Bundesministerium für Umwelt, Naturschutz und Reaktorsicherheit in Deutschland. Zusätzlich zu seiner Tätigkeit als Forscher am Institut für Geologie und Mineralogie der Universität Erlangen-Nürnberg war er in verschiedenen deutschen Geologie- und Ingenieurbüros in Afrika und Lateinamerika tätig. In unserem Unternehmen setzt er effektive Methoden ein, um die Geografie einer Umgebung zu analysieren und deren Entwicklung strategisch zu planen.',
  },
  
  {
    id: 8,
    image: akhtarImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr.-Ing. Fazlullah Akhtar',
    designation: 'Management von Wasserressourcen',
    description: 'Mit Erfahrung bei renommierten Organisationen wie USAID, den Vereinten Nationen (UNFAO), der Deutschen Welthungerhilfe (DWHH) und dem Zentrum für Entwicklungsforschung (ZEF, Deutschland) hat er sich als Experte einen Namen gemacht. Für seine herausragende Arbeit wurde er mit zahlreichen Auszeichnungen geehrt. Sein Fachgebiet umfasst die nachhaltige Nutzung verschiedener Wasserressourcen sowie den Einsatz geographischer Computerprogramme. In unserem Unternehmen leitet er das Ressort für Wasser- und Umwelttechnik.',
  }, */
  {
    id: 9,
    image: raofiImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Atik Raofi',
    designation: 'Lieferkette - Transport',
    description: 'Unser Experte für Transport- und Lieferkettenprozesse ist verantwortlich für die effiziente Gestaltung und Umsetzung von Transport- und Lieferkettenabläufen. Mit seiner umfassenden Erfahrung, die er bei Siemens Mobility im Bereich Infrastruktur gesammelt hat, liegt sein Fokus auf der ganzheitlichen Steuerung der Lieferkette, insbesondere im Bereich Transport. Darüber hinaus bringt er wertvolle Expertise aus seiner Tätigkeit bei renommierten internationalen Unternehmen wie Continental in der Abteilung für Verfahrenstechnik mit.',
  },
  /* {
    id: 10,
    image: thiwaImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'David Thiwa',
    designation: 'Marketing und Vertrieb',
    description: 'Unser Experte für Marketing und Vertrieb verfügt über umfangreiche Erfahrung durch seine Tätigkeiten in verschiedenen Start-ups. Er leitet die Marketingstrategie unseres Unternehmens und ist zudem für die Betreuung von Großkunden in der Region Ostafrika verantwortlich. Seine Fachkenntnisse und sein Engagement tragen maßgeblich dazu bei, unsere Produkte und Dienstleistungen effektiv zu präsentieren und den Vertrieb in der Zielregion zu optimieren.',
  }, */
  {
    id: 11,
    image: taheraImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Tahera Nargis',
    designation: 'Kommunikation',
    description: 'Unsere Expertin für Kommunikation bringt eine vielseitige Erfahrung als zivile Kulturaktivistin mit. Mit einem zusätzlichen Schwerpunkt im Bereich Recht und interkultureller Kommunikation ist sie verantwortlich für die Kommunikationsstrategien in Zentralasien. Ihr Engagement trägt dazu bei, die kulturellen Aspekte zu berücksichtigen und eine effektive, interkulturelle Kommunikation in der Region zu gewährleisten.',
  },
  
];

function AppTeamsde() {
  return (
    <>
      <header id="header">
        <AppHeaderde />
      </header>
      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
              <h1 className='text-white text-center font-bold pt-5 m-0'>Unser Team</h1>

            </div>
          </section>
        </div>
      </div>
      <section id="teams" className="bg-white py-12">
        <div className="content-container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-28 gap-y-8">
            {teamsData.map((team) => (
              <div key={team.id} className="mb-6 md:mb-0 md:col-span-1">
                <div className="flex flex-col items-center justify-center">
                <img src={team.image} alt={team.name} className="w-48 h-48 rounded-full object-cover" />
                <h4 className="text-2xl font-semibold mt-4 text-center">{team.name}</h4>
                <p className="text-gray-600 text-lg text-center">{team.designation}</p>
                <p className="text-gray-700 text-base mt-4 text-justify">{team.description}</p>
              </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div class="button-container mx-auto">
          <div class="flex justify-between">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/de/vorteile" className='text-white'>
                      ← Ihre Vorteile
                  </a>
              </button>
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/de/kontakt" className='text-white'>
                  Kontakt →
                  </a>
              </button>
          </div>
      </div>
      <AppFooterde />
    </>
  );
}

export default AppTeamsde;
