import React from 'react';
import AppHeader from '../header';
import AppFooter from '../footer';

const AppContact = () => {
  return (
    <>
      <header id="header">
        <AppHeader />
      </header>
      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
              <h1 className="text-white text-center font-bold pt-5 m-0">Contact Details</h1>
            </div>
          </section>
        </div>
      </div>
      <section id="contact" className="content-container block contact-block">
        <div className="container mx-auto">
          <div className="title-holder text-center">
            <h2>Contact us</h2>
          </div>
          <form className="contact-form" action="https://formsubmit.co/847745f833820900aa760928f6a1db43" method="POST">
      <input type="hidden" name="_next" value="https://contra-infra.com/thankyou" />
      <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your full name"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email address"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Enter your contact number"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
      </div>
      <div className="mb-4">
        <textarea
          id="message"
          name="message"
          placeholder="Enter your contact message"
          required className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        />
      </div>
      <div className="mb-4 text-center">
        <button type="submit" className="px-6 py-3 bg-[#4b9bf6] text-white rounded-md hover:bg-sky-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
          Submit
        </button>
      </div>
    </form>
        </div>
        <div className="google-map px-3">
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2108.491311297618!2d10.108927166072531!3d53.568335771076995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTPCsDM0JzA3LjkiTiAxMMKwMDYnNDQuMiJF!5e0!3m2!1sen!2sde!4v1695133967284!5m2!1sen!2sde" className="w-full h-96" />
        </div>
        <div className="container mx-auto">
          <div className="contact-info">
            <ul>
              <li className="flex items-center">
                <i className="fas fa-envelope mr-2"></i>
                info@contra-infra.com
              </li>
              <li className="flex items-center">
                <i className="fas fa-phone mr-2"></i>
                +49 40 6541 3880 | +49 40 6541 3879
              </li>
              <li className="flex items-center">
                <i className="fas fa-map-marker-alt mr-2"></i>
                Hamburg, Germany
              </li>
            </ul>
          </div>
        </div>
      </section>
      <AppFooter />
    </>
  );
};

export default AppContact;
