import AppFooterde from '../de_footer';
import AppHeaderde from '../de_header';
import React, { useState, useEffect } from 'react';
import RouteA_2 from '../../../src/assets/images/RouteA_2.png';



const maritimedata = [
    { data: "Geschwindigkeit in kn", shortestRoute: "5,55", fastestRoute: "5,55", saving: "" },
    { data: "Entfernung in km", shortestRoute: "5.675,19", fastestRoute: "6.021,13", saving: "-345,94" },
    { data: "Zeit in Stunden", shortestRoute: "192,33", fastestRoute: "168,24", saving: "24,09" },
    { data: "Kraftstoff in Gallonen", shortestRoute: "457.278,38", fastestRoute: "399.987", saving: "57.291,38" },
    { data: "Kraftstoff-kosten in USD", shortestRoute: "1.557.888", fastestRoute: "1.362.720", saving: "195.168" },
    { data: "Zeit, Kraftstoff und Kosten-einsparung", shortestRoute: "", fastestRoute: "", saving: "12,53%" },
  ];
  
  console.log(maritimedata);
  
  
  



function Appcasestudy4de() {
    /* const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 815); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial screen size

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); */

    return (
        <>
            <header id="header">
                <AppHeaderde/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Seerouten</h1>
                    </div>
                </section>
                </div>
            </div>
            
            <div className="content-container">
                
                <p className="text-base font-extralight text-justify text-lg text-gray-700">
                Die Bedingungen auf dem Wasser ändern sich ständig. Dynamische Wasserströmungen, Windrichtungen und Wellenhöhen beeinflussen die Fahrgeschwindigkeit von Schiffen erheblich. Unsere Software 
                nutzt Echtzeitdaten, um Routen mit minimalen Verbrauchskosten und Reisezeiten zu berechnen. Durch den Einsatz multikriterieller Optimierung finden wir die ideale Route auf hoher See. Mit 
                CONTRA steuern Sie Schiffe auf effiziente Weise durch die Gewässer.
                </p>
                <p className="text-base font-extralight text-justify text-lg text-gray-700">
                CONTRA bietet innovative Lösungen im Bereich der Seeroutenoptimierung an, die den Seeverkehr revolutionieren. 
                Das Herzstück der CONTRA-Fähigkeiten ist die geschickte Nutzung von GRIB-Daten, einer entscheidenden Komponente bei der Berechnung der effizientesten und kostengünstigsten Seerouten. 
                </p>
                <p className="text-base font-extralight text-justify text-lg text-gray-700">
                Mit präzisen Algorithmen und datengesteuerten Strategien zeichnet sich CONTRA durch die Berechnung von Seerouten aus, die nicht die kürzesten, aber die effektivsten und schnellsten sind. 
                Durch die Nutzung der Leistungsfähigkeit von GRIB-Daten können wichtige maritime Informationen wie Schiffsgeschwindigkeit, Seegang und mögliche Hindernisse berücksichtigt werden. Dieser 
                umfassende Ansatz stellt sicher, dass Frachtschiffe auf Routen geleitet werden, die maximale Effizienz garantieren.
                </p>                   
                     
                <p className="text-base font-extralight text-justify text-lg text-gray-700" >Der Einfluss von CONTRA auf die Branche ist tiefgreifend. Die sorgfältige Planung führt zu einer Zeitersparnis 
                von 24,09 Stunden und bietet Unternehmen mehr Flexibilität bei der Einhaltung enger Zeitpläne. Darüber hinaus wird die zusätzliche Entfernung, die bei der herkömmlichen Routenplanung anfällt, 
                praktisch vernachlässigbar. Darüber hinaus führt die Nutzung von CONTRA bei der Optimierung von Seerouten zu einer Senkung des Treibstoffverbrauchs um 12,53 %, was für die Reedereien sowohl 
                ökologische Nachhaltigkeit als auch erhebliche Kosteneinsparungen bedeutet.

                </p>
                
                <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-center gap-8 md:gap-16 pt-4 pb-1 md:pt-12 md:pb-16">
                    <figure>
                        <img
                        src={RouteA_2}
                        alt="glassfiber 1"
                        width={950}
                        height={333}
                        
                        />
                    </figure>
                </div>
                {/* {isLargeScreen && (
                        <div className="table-container">
                            <table className="horizontal-table">
                                <thead>
                                <tr>
                                    <th className="bg-black text-white text-center">Data</th>
                                    {maritimedata.map((val, key) => (
                                    <td key={key} className="text-center">{val.data}</td>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th className="bg-black text-red-500 text-center">CONTRA Route</th>
                                    {maritimedata.map((val, key) => (
                                    <td key={key} className='text-center text-red-500'>{val.fastestRoute}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th className="bg-black text-yellow-500 text-center">Shortest Route</th>
                                    {maritimedata.map((val, key) => (
                                    <td key={key} className='text-center text-yellow-500'>{val.shortestRoute}</td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    )} */}
                                               <div className="table-container">
                        <div className="table-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="bg-blue-200 text-sky-700 text-left" style={{ width: '40%' }}>Data</th>
                                <th className="bg-blue-200 text-sky-700 text-right">Shortest Route</th>
                                <th className="bg-blue-200 text-sky-700 text-right">CONTRA Route</th>
                                <th className="bg-blue-200 text-sky-700 text-right">Saving</th>
                                </tr>
                            </thead>
                            <tbody>
                                {maritimedata.map((val, key) => (
                                <tr key={key}>
                                    <td className='text-left' style={{ width: '40%', color: val.data.includes('Time, fuel and cost saving') ? 'black' : '', fontWeight: val.data.includes('Time, Fuel and cost saving') ? 'bold' : '' }}>{val.data}</td>

                                    <td className='text-right'>{val.shortestRoute}</td>
                                    <td className='text-right'>{val.fastestRoute}</td>
                                    <td className='text-right' style={{ color: val.saving.includes('%') ? 'black' : parseFloat(val.saving) < 0 ? 'red' : 'green', fontWeight: val.saving.includes('%') ? 'bold' : 'normal' }}>{val.saving}</td>
                                    </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                <p className="text-base font-extralight pt-2 text-justify text-lg text-gray-700 text-center">
                Abbildung and Tabelle: <em>Vergleich einer gerichteten (kürzesten) Seeroute (gelb) mit der von CONTRA berechneten optimalen Seeroute (rot) von der US-Küste zu den nahen europäischen Gewässern mit Meeresströmung (weiß) im Atlantischen Ozean.</em>
                </p>
                <p className="text-base font-extralight text-justify text-lg text-gray-700" >Als Beweis für ihr Können beläuft sich allein die Reduzierung der Treibstoffkosten auf über $ 195.000 für die 
                berechnete Strecke. CONTRAs innovative Nutzung wird eine effizientere und umweltbewusstere Zukunft der globalen Schifffahrt ebnen.
                </p>


        </div>
                
                <div class="button-container mx-auto">
                    <div class="flex justify-between">
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/de/casestudy3" className='text-white'>
                                ← Energienetzwerke
                            </a>
                        </button>
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/de/vorteile" className='text-white'>
                                Ihre Vorteile →
                            </a>
                        </button>
                    </div>
                </div>
            <AppFooterde/>
        </>
    );
}
export default Appcasestudy4de;
