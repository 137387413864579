import AppHeaderde from '../de_header';
import AppFooterde from '../de_footer';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

function ProjectCard({ imgSrc, imgAlt, title, description, progressName, progress, progressRef, progressBars, bgColor, link, index }) {

  // Determine whether the image should be on the left or right based on the index
  const isImageOnLeft = index % 2 === 0;
  const imageAlignmentClass = isImageOnLeft ? 'md:flex-row items-center ' : 'md:flex-row-reverse items-center';
  const imageSizeClass = isImageOnLeft ? 'pr-4 md:w-1/4 mr-6' : 'pl-4 ml-6 md:w-1/4';
  const imageSizeClassMobile = isImageOnLeft ? 'pr-4 pb-4 mr-6 md:w-1/4 sm:w-1/4' : 'pl-4 pb-4 ml-6 md:w-1/4 sm:w-1/4';

  return (
    <div className="benifits-container">
      <article className={`flex flex-col ${imageAlignmentClass} `}>
      <div className="flex ">
        <div className={`w-48 h-48 md:w-40 md:h-40 rounded-full overflow-hidden ${imageSizeClass} sm:${imageSizeClassMobile}`}>
          <img src={imgSrc} alt={imgAlt} className="w-full h-auto md:w-full md:h-full rounded-full" />
        </div>
      </div>
        <div className="w-full">
          <h1 className="mt-0 text-2xl break-words md:text-left">{title}</h1>
          <p className="text-base font-extralight text-justify text-lg text-gray-700">{description}</p>
          {progressBars && progressBars.length > 0 && (  // Check if progressBars exist
            <div className="progress-block pb-2">
              <div className="mb-1 text-base font-semibold dark-text-white">{progressBars[0].progressName}</div>
              <div className="flex items-center">
                <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden" ref={progressBars[0].progressRef}>
                  <div className="h-4 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-center py-1 rounded-full" style={{ width: `${progressBars[0].progress}%` }}></div>
                </div>
                <div className="text-sm text-gray-500 ml-4">{progressBars[0].progress}%</div>
              </div>
            </div>
          )}
        </div>
      </article>
    </div>
  );
}

function AppVorteilede () {

  const [progressBars, setProgressBars] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensure the animation only runs once
  });

  useEffect(() => {
    if (inView) {
      const newProgressBars = projectsData.map((project) => {
        const progress = project.progressBars[0];
        return {
          ...progress,
          ref: useInView({
            triggerOnce: true,
          })[1],
        };
      });
      setProgressBars(newProgressBars);
    }
  }, [inView]);

  const projectsData = [
    {
      imgSrc: require("../../assets/images/Firefly Rapid Infrastructure Planning 51977.jpg"),
      imgAlt: "Beschleunigte Infrastrukturplanung",
      title: "Beschleunigte Infrastrukturplanung",
      description: "Mit CONTRA profitieren unsere Kunden von einer schnellen und effizienten Infrastrukturplanung, die den Zeitaufwand für strategische Projekte erheblich reduziert. Unsere Software kann Routen und Netzwerke innerhalb weniger Stunden oder Tage berechnen, was zu einer deutlichen Zeitersparnis in der Planungs- und Entwurfsphase führt. Diese beschleunigte Abwicklung ermöglicht eine schnellere Projektumsetzung und verkürzt die Markteinführungszeit erheblich, was unseren Kunden einen entscheidenden Wettbewerbsvorteil verschafft.",
      
      progressBars: [
        {
          progress: 20, // Initial progress
          progressName: "Zeiteffizienz",
        },
      ], 
      
    },
    {
      imgSrc: require("../../assets/images/Firefly Cost-Efficiency ,minimum cost design 16420.jpg"),
      imgAlt: "Kosteneffizienz",
      title: "Kosteneffizienz",
      description: "CONTRA verwendet fortschrittliche Optimierungsmethoden und intelligente Algorithmen, die zu einer kosteneffizienten Planung führen. Dies bedeutet geringere Baukosten für Infrastrukturprojekte, was unseren Kunden wirtschaftliche Vorteile verschafft.",
      progressBars: [
        {
          progress: 46, // Initial progress
          progressName: "Kosteneinsparung",
        },
      ],
       
    },
    {
      imgSrc: require("../../assets/images/Firefly Optimal Energy Network Planning concept; lamp against a night city background 16420.jpg"),
      imgAlt: "Qualitätssteigerung",
      title: "Qualitätssteigerung",
      description: "Unsere Software erweitert ihren Nutzen auf die Planung von Energienetzen, einschließlich Rohrleitungen, Glasfasernetzen, Gasnetzen, Stromnetzen und Wasserleitungen. Der Einsatz von Algorithmen gewährleistet eine nachhaltige Planung und hilft unseren Kunden, ihre Ressourcen optimal zu nutzen. Im Vergleich zur herkömmlichen Vorgehensweise ermöglicht CONTRA präzise berechnete Ergebnisse unter Berücksichtigung von Entscheidungsträgern und vordefinierten Kriterien. Das Ergebnis: optimale Lösungen.",
      progressBars: [
        {
          progress: 99, // Initial progress
          progressName: "Qualitätssteigerung",
        },
      ],
      
    },
    {
      imgSrc: require("../../assets/images/Firefly Environmental Benefits, reduce co2 emissions and carbon footprint; sustainability concept 68.jpg"),
      imgAlt: "Ökologische Vorteile",
      title: "Ökologische Vorteile",
      description: "Die Fähigkeit von CONTRA, kleinste Netze zu berechnen, um Material zu sparen und Entfernungen zu verringern, trägt zur Reduzierung von Reibung und Druck bei, was letztendlich zu einer Senkung der CO2-Emissionen führt. Unsere Kunden können ihre Umweltverantwortung und Nachhaltigkeit durch ihre Infrastrukturprojekte fördern und damit einen positiven Beitrag zum Umweltschutz leisten.",
      progressBars: [],
      
    },
    {
      imgSrc: require("../../assets/images/Firefly data driven, GIS data to analyze soil conditions for small business development projects; co.jpg"),
      imgAlt: "Fundierte Entscheidungen auf Grundlage von Daten",
      title: "Fundierte Entscheidungen auf Grundlage von Daten",
      description: "CONTRA nutzt Geoinformationssystem (GIS)-Daten zur Analyse der Bodenbeschaffenheit, der Umweltfaktoren und der topografischen Höhendaten. Dieser datengestützte Ansatz ermöglicht eine präzise und fundierte Entscheidungsfindung und verringert das Risiko unvorhergesehener Herausforderungen während des Bauprozesses. Mit CONTRA haben Sie die Gewissheit, dass Ihre Entscheidungen auf einer soliden, datenbasierten Grundlage getroffen werden.",
      progressBars: [], 
    },
    {
      imgSrc: require("../../assets/images/Firefly Versatility in Route Planning 16420.jpg"),
      imgAlt: "Flexibilität bei der Routenplanung",
      title: "Flexibilität bei der Routenplanung",
      description: "Unsere Software ermöglicht Ihnen eine intermodale Straßenplanung, die verschiedene Länder miteinbezieht und dabei unterschiedliche Infrastruktursegmente wie Straßen, Eisenbahnen, Pipelines, Tunnel und Brücken berücksichtigt. Diese bemerkenswerte Vielseitigkeit gewährleistet, dass unsere Kunden verschiedene Transportnetzwerke äußerst effizient planen können. Mit CONTRA haben Sie die Flexibilität, Routen zu gestalten, die den spezifischen Anforderungen Ihres Projekts gerecht werden, unabhängig von geografischen Grenzen.",
      progressBars: [], 
    },
    
    {
      imgSrc: require("../../assets/images/Firefly Industrial Site Planning- map 51977.jpg"),
      imgAlt: "Industrielle Standortplanung",
      title: "Industrielle Standortplanung",
      description: "Unsere Software unterstützt Sie bei der Auswahl des optimalen Standorts für Industrie- und Fabrikanlagen und berücksichtigt dabei eine breite Palette von Parametern. Diese umfassende Herangehensweise ermöglicht es unseren Kunden, Standorte auszuwählen, die das höchste Umsatzpotenzial bieten, während gleichzeitig Transport- und Versorgungskosten minimiert werden. Das Ergebnis: profitablere und nachhaltigere Industrieunternehmen. Mit CONTRA gestalten Sie Ihre Standortentscheidungen auf eine Weise, die Ihr Geschäft langfristig stärkt.",
      progressBars: [], 
    },
    // Add more projects as needed
  ];

  return (
    <>
      <header id="header" style={{ color: 'black' }}>
        <AppHeaderde />
      </header>

      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
            <h1 className='text-white text-center font-bold pt-5 m-0'>Ihre Vorteile</h1>
            </div>
          </section>
        </div>
      </div>

      {projectsData.map((project, index) => {
      if (project.showProgressBar) {
        return (
          <ProjectCard
            key={index}
            index={index}
            imgSrc={project.imgSrc}
            imgAlt={project.imgAlt}
            title={project.title}
            description={project.description}
            progress={project.progressBars[0].progress}
            progressRef={project.progressBars[0].progressRef}
            progressName={project.progressBars[0].progressName}
          />
        );
      } else {
        return <ProjectCard key={index} index={index} {...project} />;
      }
    })}
    <div class="button-container mx-auto">
          <div class="flex justify-between">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/de/dienstleistungen" className='text-white'>
                      ← Unsere Dienstleistungen
                  </a>
              </button>
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/de/team" className='text-white'>
                      Team →
                  </a>
              </button>
          </div>
      </div>

      <AppFooterde />
    </>
  );
}

export default AppVorteilede;
