import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Apphome from './components/pages/homepage';
import AppAbout from './components/pages/about';
import Appbenifits from './components/pages/benefits';
import AppServices from './components/pages/services';
import AppTeams from './components/pages/teams';
import AppContact from './components/pages/contact';

import Apphomede from './de_components/de_pages/de_homepage';
import AppDienstleistungen from './de_components/de_pages/de_dienstleistungen';
import AppTeamsde from './de_components/de_pages/de_teams';
import Appcasestudy1 from './components/pages/casestudy1';
import Appcasestudy2 from './components/pages/casestudy2';
import App from './App';
import Appcasestudy3 from './components/pages/casestudy3';
import Appcasestudy4 from './components/pages/casestudy4';
import AppPrivacyPolicy from './components/privacypolicy';
import AppVorteilede from './de_components/de_pages/de_vorteile ';
import AppAboutde from './de_components/de_pages/de_about';
import AppKontakt from './de_components/de_pages/de_kontakt';
import Appimpression from './components/impression';
import Appimpressionde from './de_components/de_impression';
import Appcasestudy1de from './de_components/de_pages/de_casestudy1';
import Appcasestudy2de from './de_components/de_pages/de_casestudy2';
import Appcasestudy3de from './de_components/de_pages/de_casestudy3';
import Appcasestudy4de from './de_components/de_pages/de_casestudy4';
import AppPrivacyPolicyde from './de_components/de_privacypolicy';
import Appthankyou from './components/pages/thankyou';
import AppDanke from './de_components/de_pages/danke';



const router = createBrowserRouter([
  {
    path: "/",
    element: <Apphome/>,
  },
  {
    path: "/en",
    element: <Apphome/>,
  },

  {
    path: "/en/Whatiscontra",
    element: <AppAbout/>,
  },

  {
    path: "/en/benefits",
    element: <Appbenifits/>,
  },

  {
    path: "/en/services",
    element: <AppServices/>,
  },

  {
    path: "/en/team",
    element: <AppTeams/>,
  },

  {
    path: "/en/contact",
    element: <AppContact/>,
  },

  {
    path: "/de",
    element: <Apphomede/>,
  },

  {
    path: "/de/home",
    element: <Apphomede/>,
  },
  {
    path: "/de/dienstleistungen",
    element: <AppDienstleistungen/>,
  },

  {
    path: "/de/team",
    element: <AppTeamsde/>,
  },
  {
    path: "/en/casestudy1",
    element: <Appcasestudy1/>,
  },
  {
    path: "/en/casestudy2",
    element: <Appcasestudy2/>,
  },
  {
    path: "/en/casestudy3",
    element: <Appcasestudy3/>,
  },
  {
    path: "/en/casestudy4",
    element: <Appcasestudy4/>,
  },
  {
    path: "/en/privacypolicy",
    element: <AppPrivacyPolicy/>,
  },
  {
    path: "/de/vorteile",
    element: <AppVorteilede/>,
  },
  {
    path: "/de/was_ist_contra",
    element: <AppAboutde/>,
  },
  {
    path: "/de/kontakt",
    element: <AppKontakt/>,
  },
  {
    path: "/en/impression",
    element: <Appimpression/>,
  },
  {
    path: "/de/impressum",
    element: <Appimpressionde/>,
  },
  {
    path: "/de/casestudy1",
    element: <Appcasestudy1de/>,
  },
  {
    path: "/de/casestudy2",
    element: <Appcasestudy2de/>,
  },
  {
    path: "/de/casestudy3",
    element: <Appcasestudy3de/>,
  },
  {
    path: "/de/casestudy4",
    element: <Appcasestudy4de/>,
  },
  {
    path: "/de/datenschutz",
    element: <AppPrivacyPolicyde/>,
  },
  {
    path: "/thankyou",
    element: <Appthankyou/>,
  },
  {
    path: "/danke",
    element: <AppDanke/>,
  },
  
  

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <App />

    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
