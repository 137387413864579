import AppHeader from '../header';
import AppFooter from '../footer';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

function ProjectCard({ imgSrc, imgAlt, title, description, progressName, progress, progressRef, progressBars, bgColor, link, index }) {

  // Determine whether the image should be on the left or right based on the index
  const isImageOnLeft = index % 2 === 0;
  const imageAlignmentClass = isImageOnLeft ? 'md:flex-row items-center ' : 'md:flex-row-reverse items-center';
  const imageSizeClass = isImageOnLeft ? 'pr-4 md:w-1/4 mr-6' : 'pl-4 ml-6 md:w-1/4';
  const imageSizeClassMobile = isImageOnLeft ? 'pr-4 pb-4 mr-6 md:w-1/4 sm:w-1/4' : 'pl-4 pb-4 ml-6 md:w-1/4 sm:w-1/4';

  return (
    <div className="benifits-container">
      <article className={`flex flex-col ${imageAlignmentClass} `}>
      <div className="flex ">
        <div className={`w-48 h-48 md:w-40 md:h-40 rounded-full overflow-hidden ${imageSizeClass} sm:${imageSizeClassMobile}`}>
          <img src={imgSrc} alt={imgAlt} className="w-full h-auto md:w-full md:h-full rounded-full" />
        </div>
      </div>
        <div className="w-full">
          <h1 className="mt-0 text-2xl break-words md:text-left">{title}</h1>
          <p className="text-base font-extralight text-justify text-lg text-gray-700">{description}</p>
          {progressBars && progressBars.length > 0 && (  // Check if progressBars exist
            <div className="progress-block pb-2">
              <div className="mb-1 text-base font-semibold dark-text-white">{progressBars[0].progressName}</div>
              <div className="flex items-center">
                <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden" ref={progressBars[0].progressRef}>
                  <div className="h-4 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-center py-1 rounded-full" style={{ width: `${progressBars[0].progress}%` }}></div>
                </div>
                <div className="text-sm text-gray-500 ml-4">{progressBars[0].progress}%</div>
              </div>
            </div>
          )}
        </div>
      </article>
    </div>
  );
}

function Appbenifits() {

  const [progressBars, setProgressBars] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensure the animation only runs once
  });

  useEffect(() => {
    if (inView) {
      const newProgressBars = projectsData.map((project) => {
        const progress = project.progressBars[0];
        return {
          ...progress,
          ref: useInView({
            triggerOnce: true,
          })[1],
        };
      });
      setProgressBars(newProgressBars);
    }
  }, [inView]);

  const projectsData = [
    {
      imgSrc: require("../../assets/images/Firefly Rapid Infrastructure Planning 51977.jpg"),
      imgAlt: "Rapid Infrastructure Planning",
      title: "Rapid Planning",
      description: "CONTRA offers customers the advantage of rapid and efficient infrastructure planning, significantly reducing the time and effort required for strategic projects. The software's ability to calculate routes and networks within a matter of hours or days results in substantial time savings during the planning and design phase. This accelerated process enables faster project execution and shorter time-to-market, giving customers a competitive edge.",
      
      progressBars: [
        {
          progress: 20, // Initial progress
          progressName: "Time reduction",
        },
      ], 
      
    },
    {
      imgSrc: require("../../assets/images/Firefly Cost-Efficiency ,minimum cost design 16420.jpg"),
      imgAlt: "Cost-Efficiency",
      title: "Cost-Efficiency",
      description: "CONTRA utilizes advanced optimization methods and intelligent algorithms, which lead to cost-efficient planning. This means reduced construction costs for infrastructure projects, making it economically advantageous for customers.",
      progressBars: [
        {
          progress: 46, // Initial progress
          progressName: "Cost reduction",
        },
      ],
       
    },
    {
      imgSrc: require("../../assets/images/Firefly Optimal Energy Network Planning concept; lamp against a night city background 16420.jpg"),
      imgAlt: "Quality improvement",
      title: "Quality improvement",
      description: "The software extends its utility to energy network planning, including pipelines, fiber optic networks, gas networks, electricity networks, and water pipelines. The use of algorithms ensures a sustainable and cost-efficient design, helping customers make the most of their resources. Compared to the conventional approach, CONTRA calculates results precisely, considering decision-makers and previously defined criteria. The resulting outcomes are optimal.",
      progressBars: [
        {
          progress: 99, // Initial progress
          progressName: "Quality improvement",
        },
      ],
      
    },
    {
      imgSrc: require("../../assets/images/Firefly Environmental Benefits, reduce co2 emissions and carbon footprint; sustainability concept 68.jpg"),
      imgAlt: "Environmental Benefits",
      title: "Environmental Benefits",
      description: "CONTRA's ability to calculate the smallest networks to save materials and reduce distances contributes to lower friction and pressure reductions, ultimately reducing CO2 emissions. Customers can promote sustainability and environmental responsibility through their infrastructure projects.",
      progressBars: [],
      
    },
    {
      imgSrc: require("../../assets/images/Firefly data driven, GIS data to analyze soil conditions for small business development projects; co.jpg"),
      imgAlt: "Data-Driven Decisions",
      title: "Data-Driven Decisions",
      description: "CONTRA utilizes GIS data to analyze soil conditions, environmental factors, and topographic elevation data. This data-driven approach allows for informed decision-making, reducing the risk of unforeseen challenges during construction.",
      progressBars: [], 
    },
    {
      imgSrc: require("../../assets/images/Firefly Versatility in Route Planning 16420.jpg"),
      imgAlt: "Versatility in Route Planning",
      title: "Versatility in Route Planning",
      description: "For road planning, the software can calculate intermodal routes across countries, incorporating various infrastructure segments like roads, railroads, pipelines, tunnels, and bridges. This versatility ensures that customers can plan diverse transportation networks efficiently.",
      progressBars: [], 
    },
    
    {
      imgSrc: require("../../assets/images/Firefly Industrial Site Planning- map 51977.jpg"),
      imgAlt: "Industrial Site Planning",
      title: "Industrial Site Planning",
      description: "The software assists in optimal location planning for industries and factories, considering a wide range of parameters. This helps customers choose locations that offer the best sales potential while minimizing transportation and supply costs, leading to more profitable and sustainable industrial operations.",
      progressBars: [], 
    },
    // Add more projects as needed
  ];

  return (
    <>
      <header id="header" style={{ color: 'black' }}>
        <AppHeader />
      </header>

      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
            <h1 className='text-white text-center font-bold pt-5 m-0'>Your Benefits</h1>
            </div>
          </section>
        </div>
      </div>

      {projectsData.map((project, index) => {
      if (project.showProgressBar) {
        return (
          <ProjectCard
            key={index}
            index={index}
            imgSrc={project.imgSrc}
            imgAlt={project.imgAlt}
            title={project.title}
            description={project.description}
            progress={project.progressBars[0].progress}
            progressRef={project.progressBars[0].progressRef}
            progressName={project.progressBars[0].progressName}
          />
        );
      } else {
        return <ProjectCard key={index} index={index} {...project} />;
      }
    })}
      <div class="button-container mx-auto">
          <div class="flex justify-between">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/services" className='text-white'>
                      ← Our Services
                  </a>
              </button>
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/team" className='text-white'>
                      Team →
                  </a>
              </button>
          </div>
      </div>
      <AppFooter />
    </>
  );
}

export default Appbenifits;
