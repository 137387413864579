import AppFooter from '../footer';
import AppHeader from '../header';
import React from 'react';
import gf1 from '../../../src/assets/images/Fiber_Optic.jpg';
import gf2 from '../../../src/assets/images/Glasfaser1.png';
import './casestudy.css';

const data = [
    { net: "existing network", len: "441 m", cost: "36.979 €" },
    { net: "CONTRA network", len: "466 m", cost: "22.934 €" },
    { net: "savings (absolut) ", len: "-25 m" , cost: "14.045 €" },
]

function Appcasestudy2() {
    return (
        <>
            <header id="header">
                <AppHeader/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Energy Network</h1>
                    </div>
                </section>
                </div>
            </div>
            
                <div className="content-container">
                
                    
                         <p className="text-base font-extralight text-justify text-lg text-gray-700">Energy Networks CONTRA can calculate 
                            optimal energy and broadband networks to cover large areas with any number of households. This includes fiber optic, 
                            electricity and gas networks as well as water pipelines. Our algorithms can calculate both the cheapest and the smallest 
                            network in terms of network laying costs and network length.
                        </p>
                         <p className="text-base font-extralight text-justify text-lg text-gray-700">During the planning of fiber optic networks, GIS data of the surrounding area are also used as input, 
                            in addition to the coordinates of the households and the different laying costs according to the ground 
                            conditions. Afterwards, an objective function is selected for the optimization. The objective function 
                            can be for example the <em> minimization of the network construction costs</em> on different grounds or the
                            <em> minimization of the network size.</em> 
                        </p>
                        <p className="text-base font-extralight text-justify text-lg text-gray-700" >After that, all data are automatically evaluated and an optimal network 
                            with either minimum laying costs or minimum network length between households is determined. As an output, 
                            the software drafts a summary of the data of a network. The summary includes the <em>network design, the 
                            construction costs and the network length.</em> We do not only provide the design of a specific network 
                            but also determine different network scenarios, with all associated data and information.
                        </p>
                        <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-start gap-8 md:gap-16 pt-4 pb-2 md:pt-12 md:pb-16">
                            <figure >
                                <img
                                    src={gf2}
                                    alt="glassfiber 1"
                                    width={475}
                                    height={333}
                                    
                                />
                            </figure>
                            <figure>
                                <img
                                    src={gf1}
                                    alt="optical fiber 2"
                                    width={500}
                                    height={333}
                                    
                                />
                            </figure>
                            
                        </div>
                        <p className="text-base font-extralight text-center text-lg text-gray-700">Figures:  <em>Comparison of a manual fiber network planning (left) versus CONTRA planning (right).</em></p>
                        <div className="table-container">
                            <table>
                                <tr>
                                    <th>Fiber optics network</th>
                                    <th>Length</th>
                                    <th>Const. cost</th>
                                </tr>
                                {data.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{val.net}</td>
                                            <td>{val.len}</td>
                                            <td>{val.cost}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <th>savings (in %)</th>
                                    <th> -5,7 %</th>
                                    <th>38 %</th>
                                </tr>
                            </table>
                        </div>
                    <p className="text-base font-extralight text-center text-lg text-gray-700">Table:  <em>Comparison of the length and costs of a manual planning versus the CONTRA planning.</em></p>
                    
                    
                    
                        
                        <p className="text-base font-extralight text-justify text-lg text-gray-700" >After that, all data are automatically evaluated and an optimal network 
                            with either minimum laying costs or minimum network length between households is determined. As an output, 
                            the software drafts a summary of the data of a network. The summary includes the <em>network design, the 
                            construction costs and the network length.</em> We do not only provide the design of a specific network 
                            but also determine different network scenarios, with all associated data and information.</p>
                        
                        
                   
                </div>
                <div class="button-container mx-auto">
                    <div class="flex justify-between">
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/en/casestudy1" className='text-white'>
                                ← Route Planning
                            </a>
                        </button>
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/en/casestudy3" className='text-white'>
                                Telecommunication →
                            </a>
                        </button>
                    </div>
                </div>
           
            <AppFooter/>
        </>
    );
}
export default Appcasestudy2;
