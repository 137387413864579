import whitelogo from '../assets/images/white_logo.png';
import logo from '../assets/images/logo1.png';
import en from '../assets/images/en.png';
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavDropdown from 'react-bootstrap/NavDropdown';


const paths = ['/de/dienstleistungen', '/de/casestudy1', '/de/casestudy2', '/de/casestudy3', '/de/casestudy4'];

const pageMapping = {
    '/de': '/',
    '/de/was_ist_contra': '/en/Whatiscontra',
    '/de/dienstleistungen': '/en/services',
    '/de/vorteile': '/en/benefits',
    '/de/casestudy1': '/en/casestudy1',
    '/de/casestudy2': '/en/casestudy2',
    '/de/casestudy3': '/en/casestudy3',
    '/de/casestudy4': '/en/casestudy4',
    '/de/team': '/en/team',
    '/de/kontakt': '/en/contact',
    '/de/impressum': '/en/impression',
    '/de/datenschutz': '/en/privacypolicy',
};
  
export default function AppHeader() {
    const [navbar, setNavbar] = useState(false);
    const [isHomePage, setIsHomePage] = useState(window.location.pathname === '/de'); // Add this line

    const changeBackground = () => {
        if(window.scrollY >= 50) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', changeBackground);

        const handlePathChange = () => { // Add this block
            setIsHomePage(window.location.pathname === '/de');
        };
        window.addEventListener('popstate', handlePathChange);

        return () => {
            window.removeEventListener('scroll', changeBackground);
            window.removeEventListener('popstate', handlePathChange); // Add this line
        };
    }, []);

    return (


        <Navbar expand="lg" className={`${navbar ? 'active' : isHomePage ? 'home' : ''}`}>


            <Container>
                <Navbar.Brand href="/de">
                    <div>
                        <img src={navbar ? logo : whitelogo} alt ="contra" className='logo-container logo-change'/>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav  className="me-auto" >
                    <Nav.Link className={`${!navbar ? 'text-white' : 'text-black'} ${location.pathname === '/de' ? '' : 'hover:border-b'}`} href="/de" ><span className={`${!navbar ? 'border-white ' : 'border-black'} ${location.pathname === '/de' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Home</span></Nav.Link>
                        <Nav.Link className={`${!navbar ? 'text-white' : 'text-black'} ${location.pathname === '/de/was_ist_contra' ? '' : 'hover:border-b'}`} href="/de/was_ist_contra"><span className={`${!navbar ? 'border-white' : 'border-black'} ${location.pathname === '/de/was_ist_contra' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Was ist CONTRA?</span></Nav.Link>
                        <NavDropdown title={<span className={`dropdown-title ${!navbar ? 'text-white border-white' : 'text-black border-black'} ${paths.includes(location.pathname) ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Unsere Dienstleistungen</span>} id="nav-dropdown">
                            <NavDropdown.Item className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/de/dienstleistungen' ? '' : 'hover:border-b'}`} href="/de/dienstleistungen"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/de/dienstleistungen' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Übersicht</span></NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/de/casestudy1' ? '' : 'hover:border-b'}`} href="/de/casestudy1"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/de/casestudy1' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Routenplanung</span></NavDropdown.Item>
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/de/casestudy2' ? '' : 'hover:border-b'}`} href="/de/casestudy2"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/de/casestudy2' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Energienetzwerke</span></NavDropdown.Item>
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/de/casestudy3' ? '' : 'hover:border-b'}`} href="/de/casestudy3"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/de/casestudy3' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Telekommunikation</span></NavDropdown.Item>
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/de/casestudy4' ? '' : 'hover:border-b'}`} href="/de/casestudy4"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/de/casestudy4' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Seerouten</span></NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className={`${!navbar ? 'text-white' : 'text-black'} ${location.pathname === '/de/vorteile' ? '' : 'hover:border-b'}`} href="/de/vorteile"><span className={`${!navbar ? 'border-white' : 'border-black'} ${location.pathname === '/de/vorteile' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Ihre Vorteile</span></Nav.Link>
                        <Nav.Link className={`${!navbar ? 'text-white' : 'text-black'} ${location.pathname === '/de/team' ? '' : 'hover:border-b'}`} href="/de/team"><span className={`${!navbar ? 'border-white' : 'border-black'} ${location.pathname === '/de/team' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Team</span></Nav.Link>
                        <Nav.Link className={`${!navbar ? 'text-white' : 'text-black'} ${location.pathname === '/de/kontakt' ? '' : 'hover:border-b'}`} href="/de/kontakt" ><span className={`${!navbar ? 'border-white' : 'border-black'} ${location.pathname === '/de/kontakt' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Kontakt</span></Nav.Link>
                        <Nav.Link href={pageMapping[location.pathname] || '/'} className="flag-image">
                            <img
                            src={en}
                            alt="English"
                            className="flag-image"
                            />
                        </Nav.Link>
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        
    )
}