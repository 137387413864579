import React from 'react';
import AppHeader from '../header';
import AppFooter from '../footer';
import fawadImage from '../../../src/assets/images/fawad.jpeg'; // Import individual images for each team member
import fuegenschuhImage from '../../../src/assets/images/Fuegenschuh.jpg';
import mantwillImage from '../../../src/assets/images/mantwill.jpg';
import qaisZazaiImage from '../../../src/assets/images/Qais-Zazai.jpeg';
import roostaiImage from '../../../src/assets/images/Roostai.jpg';
import redlichImage from '../../../src/assets/images/Tobias-Redlich.jpg';
import akhtarImage from '../../../src/assets/images/Faizl-Akhtar.jpg';
import raofiImage from '../../../src/assets/images/Atik_Raofi.jpg';
import thiwaImage from '../../../src/assets/images/david-thiwa.jpg';
import taheraImage from '../../../src/assets/images/Tahera.jpg';
import ankitImage from '../../../src/assets/images/Ankit.jpg';

const teamsData = [
  {
    id: 1,
    image: fawadImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr. M. Fawad Zazai',
    designation: 'Chief Executive Officer & Founder',
    description: 'Dr. Zazai worked as a consultant for international transport companies and specialized in algorithm development and optimization techniques for scientific and complex problems in the field of infrastructure. He developed the algorithms and methods behind the software CONTRA. Moreover, he develops innovative software for scientific and practical problems. Another field he is working on is the modeling and optimization of various processes. He is also a scientist in Applied Mathematics at the Federal Armed Forces University in Hamburg.',
  },
  {
    id: 2,
    image: qaisZazaiImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'M. Qais Zazai',
    designation: 'Chief Operating Officer & Business Development Manager',
    description: 'Mr. Zazai has completed his MBA with a main focus on sustainability at the University of Hamburg and started his own foreign start-up company. Through his knowledge as a social entrepreneur, he enriches the team and ensures CONTRA’s strategic orientation. In addition, as a marketing expert, he will represent the company to the outside world and is responsible for concept creation and implementation.',
  },
  {
    id: 3,
    image: mantwillImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Prof. Dr.-Ing. Frank Mantwill',
    designation: 'Project Mangement & Strategic Advisor',
    description: 'Professor Mantwill is the Professor in Machine Elements and Computer Aided Product Development at the Helmut Schmidt University in Hamburg. He was a leading figure in the field of product and process development in the industry. Most recently, he was head of production planning for a car body shop at a German automobile manufacturer in the premium segment. He is primarily specialized in product and process development. He plans the projects and manages the project implementation. In addition, he develops the strategies of the company.',
  },
  {
    id: 4,
    image: fuegenschuhImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Prof. Dr. Armin Fügenschuh',
    designation: 'Chief Technology Officer & Scientific Advisor',
    description: 'Professor Fügenschuh is the Professor of Engineering Mathematics and Numerical Optimization at the Brandenburg University of Technology in Germany. He helped implement IT solutions and is working on our upcoming application programming interfaces. He develops new algorithms and optimization methods for the implementation of projects. He is specialized in the field of mixed-integer optimization and finding solutions for difficult and heavy problems. He develops solution methods and concepts for practical problems.',
  },
  {
    id: 5,
    image: redlichImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr.-Ing. Tobias Redlich',
    designation: 'Engineer & Technical Advisor',
    description: 'Dr. Redlich is a scientific advisor and responsible for technical and engineering issues. Furthermore, he is the Managing Director of the Hamburg Institute for Value Creation Systematics and Knowledge Management (HIWW) and academic head of the Laboratory for Manufacturing Technology (LaFT) at Helmut Schmidt University in Hamburg.',
  },
  {
    id: 6,
    image: ankitImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Ankit Vaghasiya',
    designation: 'Software Developer',
    description: 'Mr. Vaghasiya studied physics at the University of Hamburg. Parallel to his studies, he has also immersed himself in the world of web development and software engineering, where he has explored the fascinating intersection of science and technology and has incorporated it profitably into the company.',
  },
/*   {
    id: 7,
    image: roostaiImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr. Abdul Hanan Roostai',
    designation: 'Geophysicist & Geologist',
    description: 'Dr. Roostai has worked as a consultant in the Federal Ministry for the Environment, Nature Conservation, and Nuclear Safety of Germany. He was also a researcher at the Institute for Geology and Mineralogy of the University of Erlangen-Nuremberg in Germany. Furthermore, he worked for several German geological and engineering companies in Africa and Latin America. He uses effective methods to analyze the geography of an environment and plans its development.',
  },
  
  {
    id: 8,
    image: akhtarImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Dr.-Ing. Fazlullah Akhtar',
    designation: 'Water Resource Management',
    description: 'Mr. Akhtar has worked for USAID, United Nations (UNFAO), German Agro Action (DWHH), and Centre for Developing Research (ZEF, Germany). He has won numerous awards for his work. He is specialized in the use of different water resources and geographical computer programs. He is responsible for water and environmental engineering.',
  }, */
  {
    id: 7,
    image: raofiImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Atik Raofi',
    designation: 'Supply Chain – Transport',
    description: 'Mr. Raofi is responsible for transportation and supply chain processes. He worked for Siemens Mobility (Infrastructure) for the whole supply chain including transportation. He worked also for big international companies like Continental in the Department of Process Engineering.',
  },
  /* {
    id: 10,
    image: thiwaImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'David Thiwa',
    designation: 'Marketing & Sales',
    description: 'Mr. Thiwa has worked in multiple start-ups and he directs the marketing strategy of the company. He also handles key accounts in the East Africa Region.',
  }, */
  {
    id: 8,
    image: taheraImage,
    fbLink: 'https://www.facebook.com',
    twitterLink: 'https://www.twitter.com',
    linkedinLink: 'https://www.linkedin.com',
    name: 'Tahera Nargis',
    designation: 'Communication',
    description: 'Miss Nargis has worked as a civil cultural activist. Her additional field of expertise is law and intercultural communication. She is responsible for the communication in Central Asia.',
  },
  
];

function AppTeams() {
  return (
    <>
      <header id="header">
        <AppHeader />
      </header>
      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
              <h1 className='text-white text-center font-bold pt-5 m-0'>Our Team</h1>

            </div>
          </section>
        </div>
      </div>
      <section id="teams" className="bg-white py-12">
        <div className="content-container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-28 gap-y-8">
            {teamsData.map((team) => (
              <div key={team.id} className="mb-6 md:mb-0 md:col-span-1">
                <div className="flex flex-col items-center justify-center">
                <img src={team.image} alt={team.name} className="w-48 h-48 rounded-full object-cover" />
                <h4 className="text-2xl font-semibold mt-4 text-center">{team.name}</h4>
                <p className="text-gray-600 text-lg text-center">{team.designation}</p>
                <p className="text-gray-700 text-base mt-4 text-justify">{team.description}</p>
              </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div class="button-container mx-auto">
          <div class="flex justify-between">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/benefits" className='text-white'>
                      ← Your Benefits
                  </a>
              </button>
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/contact" className='text-white'>
                      Contact →
                  </a>
              </button>
          </div>
      </div>
      <AppFooter />
    </>
  );
}

export default AppTeams;
