import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css';
import AppHero from '../hero';
import AppFooter from '../footer';
import Appheader from '../header';
import React from 'react';
import './homepage.css';

// Teams data
const teamsData = [
  {
    id: 1,
    imgSrc: require("../../assets/images/fiberoptics_subtitle.mp4"),
    link: "/en/casestudy1",
    title: "Fiber Optic Network",
    imgAlt: "Fiber Optic Network",
    description: "In the planning of fiber optic networks, CONTRA utilizes GIS data, household coordinates, and ground condition-based laying costs. It then selects an objective function, such as minimizing construction costs or network size. The software automates data evaluation to determine an optimal network, minimizing either laying costs or network length between households.",
  },

  {
    id: 2,
    imgSrc: require("../../assets/images/seeroute_english_subtitle.mp4"),
    link: "/en/casestudy1",
    title: "Sea Route Planning",
    imgAlt: "Sea Route Planning",
    description: "With precision-driven algorithms and data-driven strategies, CONTRA excels in computing sea routes that are not only the shortest but also the fastest. By harnessing the power of GRIB data, they can factor in crucial maritime information, including vessel speed, sea conditions, and potential obstacles. This comprehensive approach ensures that cargo vessels are guided along routes that guarantee maximum efficiency.",
  },

  {
    id: 3,
    imgSrc: require("../../assets/images/telecom.gif"),
    link: "/en/casestudy1",
    title: "5G Antenna Placement",
    imgAlt: "5G Antenna Placement",
    description: "CONTRA's intelligent algorithms optimize the placement of telecommunication antennas, crucial for comprehensive cellular network coverage. It minimizes costs by strategically positioning antennas of various power and signal coverage, like 5G, LTE, or GSM/2G. It analyzes areas point by point, determining the ideal antenna distribution and selecting the fewest antennas for extensive coverage.",
  },
];

function Apphome() {
  return (
    <div className="App">
      <header id="header">
        <Appheader />
      </header>
      <main>
        <AppHero />

        {/* Content Section */}
        <div className="content-container p-4 space-y-8">
          {teamsData.map((team) => (
            <div key={team.id} className="bg-white rounded-lg shadow-md ">
              <h3 className="text-2xl font-semibold mb-2 text-left p-3">{team.title}</h3>
              
              {/* Render video if file extension is .mp4, otherwise render img */}
              <div className="flex justify-center p-2">
                {team.imgSrc.includes('.mp4') ? (
                  <video className="w-full max-w-5xl h-auto rounded-lg" autoPlay muted loop controls style={{ maxWidth: "1300px" }}>
                    <source src={team.imgSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={team.imgSrc} alt={team.imgAlt} className="w-full max-w-5xl h-auto rounded-lg" style={{ maxWidth: "1300px" }} />
                )}
                
              </div>

              <p className="text-lg font-normal text-justify mb-4 text-gray-700 p-3">{team.description}</p>
            </div>
          ))}
        </div>

        <AppFooter />
      </main>
    </div>
  );
}

export default Apphome;
