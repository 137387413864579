import React from 'react';
import DankeHeader from '../dankeheader';

function AppDanke() {
  return (
    <>
      <header id="header">
        <DankeHeader />
      </header>

      <main className="flex flex-col h-full flex-1">
        <div className="max-w-screen-xl mx-auto px-5 pt-20">
          <div className="mt-16 text-center">
            <h1 className="text-4xl lg:text-5xl font-bold lg:tracking-tight">
              Vielen Dank!
            </h1>
            <p className="text-lg mt-4 text-slate-600">
              {/* Additional text content can be added here if needed */}
            </p>
          </div>
          <div className="flex flex-col gap-3 mx-auto max-w-4xl mt-16">
            <h2 className="font-bold text-3xl text-gray-800 text-center">
              Wir haben Ihre Nachricht erhalten.
            </h2>
            <p className="text-lg leading-relaxed text-slate-500 text-center">
              Wir werden uns so schnell wie möglich bei Ihnen melden.
            </p>
            <div className="flex justify-center mt-12">
              <a
                href="/de"
                rel="noopener"
                className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5 bg-primary text-white hover:bg-secondary border-2 border-transparent"
              >
                <span>Zurück zur Startseite</span>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="mt-20 mb-8">
        <p className="text-center text-sm text-slate-500">
          Urheberrecht © 2024 contra-infra.com. Alle Rechte vorbehalten.
        </p>
      </footer>
    </>
  );
}

export default AppDanke;
