import AppFooter from '../footer';
import AppHeader from '../header';
import React from 'react';
import coverage1 from '../../../src/assets/images/Coverage1.jpg';
import coverage2 from '../../../src/assets/images/Coverage2.jpg';
import './casestudy.css';


const data = [
    { net: "existing network", len: "441 m", cost: "36.979 €" },
    { net: "CONTRA network", len: "466 m", cost: "22.934 €" },
    { net: "savings (absolut) ", len: "-25 m" , cost: "14.045 €" },
]

function Appcasestudy3() {
    return (
        <>
            <header id="header">
                <AppHeader/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Telecommunication: 5G Antennas</h1>
                    </div>
                </section>
                </div>
            </div>
            
            <div className="content-container">
                
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">In the case of telecommunication, 
                        we are able to determine the ideal location for tele-communication antennas to maximize coverage of a geographic 
                        area and simultaneously minimize overall costs. During the calculation all kind of restrictions are taken into account, 
                        such as the topography of an area or protected areas.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">The intelligent algorithms of CONTRA can also be
                        used for the optimal planning of telecommunication
                        antennas. To ensure a comprehensive coverage,
                        many stations are required in cellular mobile radio
                        networks, and these must be set up at different
                        locations.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Antenna models with different power or signal
                        coverage, such as for 5G antennas, LTE, or GSM/
                        2G, can cause different costs. The locations of the
                        antennas are currently determined by estimation
                        methods and the dispersion of the signal is
                        simulated by ray-tracing methods on the computer
                        to check the quality of the signals.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Compared to the common approach, by using our
                        software it is possible to find optimal locations for
                        the construction of antennas in a selected area to
                        maximize signal coverage and minimize the total
                        cost of the antennas. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Our software analyzes a geographic area on a pointby-point basis, then determines the optimal
                        distribution of antenna positions across a selected
                        area and selects the minimum number of antennas
                        required for a maximum signal coverage.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">The result is a summary of all data, which includes
                        the antenna locations, the construction costs, the
                        number of antennas, and the area of signal
                        coverage.<br/><br/>
                    </p>
                    
                    
                    <hr />
                
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Example: The red marking covers the area where
                        the antennas (red dots) are to be built. The signal
                        coverage of an antenna is shown here as a circular
                        area. The yellow overlays are antennas with lower
                        range and the blue overlays represent antennas with
                        higher coverage.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Below, the figure on the right shows an optimal
                        distribution of antenna locations visualized by
                        CONTRA. In this planning, two antennas with lower
                        coverage were saved, despite sufficient coverage.
                        Due to the calculation of our software 20% fewer
                        antennas were used and thus costs were saved.
                    </p>
                    <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-start gap-8 md:gap-16 pt-4  md:pt-12 ">
                        <figure className="mx-auto w-full text-center">
                            <img
                                src={coverage1}  // Replace with the path to your blank image
                                alt="telecommunicaton image"  // Provide a suitable alt text
                                width={500}
                                height={279.42}
                                className="mx-auto bg-black"
                            />
                            <p className="text-base font-extralight pt-2 pb-2 text-justify text-lg text-gray-700 text-center">Figure 1:  <em>Manual planning of a telecommunication network.</em></p>

                        </figure>
                        
                        <figure className="mx-auto w-full text-center">
                        <img
                            src={coverage2}  // Replace with the path to your blank image
                            alt="telecommunicaton image"  // Provide a suitable alt text
                            width={500}
                            height={279.42}
                            className="mx-auto bg-black"
                        />
                        <p className=" text-base font-extralight pt-2 pb-2 text-justify text-lg text-gray-700 text-center">Figure 2:  <em>Optimal planning of a telecommunication network by CONTRA.</em></p>
                        </figure>
                    
                    </div>
                </div>
                <div class="button-container mx-auto">
                    <div class="flex justify-between">
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/en/casestudy2" className='text-white'>
                                ← Energy Network
                            </a>
                        </button>
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/en/casestudy4" className='text-white'>
                                Sea Routes →
                            </a>
                        </button>
                    </div>
                </div>
            
            <AppFooter/>
        </>
    );
}
export default Appcasestudy3;
