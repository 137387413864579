import React, { useState, useEffect } from "react";

function AppFooterde() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <section>
      {/* Your main content goes here */}
      
      {/* Footer */}
      <footer className="footer max-w-screen-xl">
        <div className="footer-center">
            <div className="footer-center-top">
              <h2>Fragen? - Schreiben Sie uns!</h2>
            </div>
            <div className="footer-left-bottom">
                <a href="/de/kontakt" className="button-link">
                Nachrichtenanfrage →
                </a>
              </div>
            <div className="footer-center-bottom">
              <p>
              <a href="/de/impressum">Impressum </a> | <a href="/de/datenschutz">Datenschutz</a> | ©&nbsp;CONTRA&nbsp;2024
              </p>
            </div>
          </div>
      </footer>
      
      {showTopBtn && (
        <div className="go-top" onClick={goTop}></div>
      )}
    </section>
  );
}

export default AppFooterde;
