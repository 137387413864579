
import React from 'react';
import ThankyouHeader from './thankyouheader.js';
import AppFooter from './footer.js';

function Appimpression() {
  
  return (
    <>
      <header id="header">
        <ThankyouHeader/>
      </header> 
        
        
       
  
      <main className="flex flex-col h-full w-full flex-1">
      <div className="fdc-content-layout bg-white text-light-grey">
        <section>
        <h1 className=" text-4xl lg:text-6xl font-bold mb-20" id="imprint">
            Imprint
          </h1>
          <p className="text-lg lg:text-2xl font-extralight ">
            CONTRA UG<br />
            Holstenhofweg 85<br />
            20359 Hamburg<br />
            Germany
          </p>
          <p className="text-lg lg:text-2xl font-extralight ">
            <a
              className="font-serif no-underline hover:text-red"
              href="mailto:info@contra-infra.com"
              target="_blank"
              rel="noopener"
            >
              info@contra-infra.com&nbsp;→
            </a>
            <br />
            +49 40 6541 3880<br />
            +49 40 6541 3879<br />
          </p>
          <p className="text-lg lg:text-2xl font-extralight ">
          Management: Dr. Fawad Zazai<br />
            
          </p>
          <p className="text-lg lg:text-2xl font-extralight ">
          HRB 175184 Amtsgericht Hamburg<br />
          St.-Nr.: 44/712/00617

          </p>
          <h2 className="text-4xl lg:text-5xl mt-12 mb-4 font-bold " id="Liability disclaimer">Liability disclaimer</h2>
          <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Liability for content">Liability for content</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            The contents of our pages were created with the utmost of care. However, we cannot guarantee the accuracy, completeness, 
            or timeliness of the content. As a service provider, we are responsible for our own content on these pages according to the general according to § 7 Abs.1 TMG.<br />
            According to §§ 8 bis 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information 
            or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under the general laws 
            remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes 
            known. If we become aware of such infringements, we will remove this content immediately.
            </p>
          <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Copyright">Copyright</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            The content and works created by the site operators on these pages are subject to German copyright law. The reproduction, editing, distribution and 
            any kind of exploitation outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of 
            this site are only permitted for private, non-commercial use. Insofar as this content on this site was not created by the operator, the copyrights 
            of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, 
            please inform us accordingly. If we become aware of any such infringements, we will remove such content immediately.
            </p>
          <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Liability for links">Liability for links</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            Our offerings here contain links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any 
            liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. 
            The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. 
            However, the constant monitoring of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we 
            become aware of any such infringements, we will remove such links immediately.
            </p>
        </section>
        
      </div>
    </main>
    <AppFooter/>
    </>
  );
};

export default Appimpression;