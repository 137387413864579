import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/Firefly The new dimension of infrastructure planning for industry construction, aerial view to city .jpg'),
    title: 'The new dimension of infrastructure planning: CONTRA',
    description: 'With our new and innovative software CONTRA, we optimize and digitalize infrastructure projects globally, taking time, cost, and the environmental impacts into account! ',
    
  },
  {
    id: 2,
    image: require('../assets/images/road.jpg'),
    title: 'Route Planning',
    description: 'With our intelligent AI-based program, we can scientifically, objectively, and optimally plan roads, and railroads, taking into account construction costs, transit, and transportation convenience.',
    
  },
  {
    id: 3,
    image: require('../assets/images/fiber.jpg'),
    title: 'Energy Network',
    description: 'We can calculate optimal energy, and broadband networks to cover large areas with any number of households. This includes fiber optic networks, electricity networks, gas networks and water pipelines.',
    
  },
  {
    id: 4,
    image: require('../assets/images/tele.jpg'),
    title: 'Telecommunication',
    description: 'In the case of telecommunication, we are able to determine the ideal location for telecommunication antennas to maximize coverage of a geographic area and simultaneously minimize overall costs.',
    
  },
  {
    id: 5,
    image: require('../assets/images/shipping.jpg'),
    title: 'Sea Routes',
    description: 'CONTRA can calculate optimal shipping routes by reducing cost, time and fuel consumption by using GRIB-data.',
    
  },
/*   {
    id: 6,
    image: require('../assets/images/railway.jpg'),
    title: 'Location Plannning',
    description: 'Besides, the algorithms of the software allow us to calculate the best locations for warehouses, industries, stores, or mines in a particular area, in order to use resources optimally and provide efficient transportation.',
    
  }, */
  
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;