import AppFooterde from '../de_footer';
import AppHeaderde from '../de_header';
import React from 'react';
import gf1 from '../../../src/assets/images/Fiber_Optic.jpg';
import gf2 from '../../../src/assets/images/Glasfaser1.png';

const data = [
    { net: "existing network", len: "441 m", cost: "36.979 €" },
    { net: "CONTRA network", len: "466 m", cost: "22.934 €" },
    { net: "savings (absolut) ", len: "-25 m" , cost: "14.045 €" },
]

function Appcasestudy2de() {
    return (
        <>
            <header id="header">
                <AppHeaderde/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Energienetzwerke</h1>
                    </div>
                </section>
                </div>
            </div>
            
                <div className="content-container">
                
                    
                        <p className="text-base font-extralight text-justify text-lg text-gray-700">CONTRA ist in der Lage, optimale Energie- und Breitbandnetze für umfangreiche 
                            Gebiete mit zahlreichen Gebäuden und Haushalten zu berechnen. Dazu gehören Glasfaser-, Strom-, Gas- und Wasserleitungen. Unsere Algorithmen können 
                            sowohl die kostengünstigste als auch die kleinste Netzwerklösung in Bezug auf Verlegungskosten und Netzlänge ermitteln. Wir sorgen für eine effiziente 
                            und nachhaltige Energieversorgung.
                        </p>
                        <p className="text-base font-extralight text-justify text-lg text-gray-700">Bei der Planung von Glasfasernetzwerken werden als Input ebenfalls GIS-Daten 
                            der Umgebung, Koordinaten der Haushalte und unterschiedliche Verlegungskosten entsprechend der Bodenbeschaffenheit, eingegeben und eine Zielfunktion 
                            für die Optimierung gewählt. Als Zielfunktion kann beispielsweise die <em> Minimierung der Netzbaukosten</em> auf unterschiedliche Böden oder die 
                            <em> Minimierung der Netzgröße</em> betrachtet werden. 
                        </p>
                        <p className="text-base font-extralight text-justify text-lg text-gray-700" >Danach werden alle Daten automatisch ausgewertet und ein optimales Netzwerk 
                        mit entweder minimalen Verlegungskosten oder minimaler Netzlänge zwischen den Haushalten ermittelt. Als Output entwirft die Software eine Zusammenfassung 
                        der Daten eines Netzwerks. Die Zusammenfassung beinhaltet die <em>Netzwerkdarstellung,</em> die <em> Konstruktionskosten </em>  und die<em>Netzwerklänge.</em> 
                        Wir erstellen nicht nur die Planung eines bestimmten Netzwerkes, sondern ermitteln auch unterschiedliche Netzwerkszenarien, mit allen dazugehörigen Daten und Informationen.
                        </p>
                        <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-start gap-8 md:gap-16 pt-4 pb-2 md:pt-12 md:pb-16">
                            <figure >
                                <img
                                    src={gf2}
                                    alt="glassfiber 1"
                                    width={475}
                                    height={333}
                                    
                                />
                            </figure>
                            <figure>
                                <img
                                    src={gf1}
                                    alt="optical fiber 2"
                                    width={500}
                                    height={333}
                                    
                                />
                            </figure>
                            
                        </div>
                        <p className="text-base font-extralight text-center text-lg text-gray-700">Abbildungen:  <em>Vergleich einer manuellen Glasfasernetzplanung (links) gegenüber der CONTRA-Planung (rechts).</em></p>
                        <div className="table-container">
                            <table>
                                <tr>
                                    <th>Fiber optics network</th>
                                    <th>Length</th>
                                    <th>Const. cost</th>
                                </tr>
                                {data.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{val.net}</td>
                                            <td>{val.len}</td>
                                            <td>{val.cost}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <th>savings (in %)</th>
                                    <th> -5,7 %</th>
                                    <th>38 %</th>
                                </tr>
                            </table>
                        </div>
                    <p className="text-base font-extralight text-center text-lg text-gray-700">Tabelle:  <em>Gegenüberstellung der Länge und Kosten einer manuellen Planung und der CONTRA-Planung.</em></p>
                       
                </div>
                <div class="button-container mx-auto">
                    <div class="flex justify-between">
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/de/casestudy1" className='text-white'>
                                ← Routenplanung
                            </a>
                        </button>
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/de/casestudy3" className='text-white'>
                            Telekommunikation →
                            </a>
                        </button>
                    </div>
                </div>
           
            <AppFooterde/>
        </>
    );
}
export default Appcasestudy2de;
