import AppFooter from '../de_footer';
import AppHeader from '../de_header';
import React from 'react';
import cs1 from '../../../src/assets/images/cs_1_1.png';
import cs2 from '../../../src/assets/images/cs_1_2.png';
import cs4 from '../../../src/assets/images/Shortest_Badghis.png';
import cs5 from '../../../src/assets/images/Cheapest_Badghis.png';
import cs6 from '../../../src/assets/images/Convenient_Badghis.png';
import cs7 from '../../../src/assets/images/Existing_Badghis.png';

function Appcasestudy1de() {
    return (
        <>
            <header id="header">
                <AppHeader/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Routenplanung</h1>
                    </div>
                </section>
                </div>
            </div>
            
            <div className="content-container">
                
            <p className="text-base font-extralight text-justify text-lg text-gray-700">Die Planung von Straßen- und Schienennetzen 
                war bisher größtenteils mit Schätzungen und auf Erfahrung basierenden Annahmen verbunden. Diese Vorgehensweisen sind 
                kostspielig, zeitaufwendig und abhängig von verschiedenen Entscheidungsträgern. Mit CONTRA bieten wir Ihnen die 
                Möglichkeit, maßgeschneiderte Straßen- und Schienenwege zu berechnen, die Ihren spezifischen Optimierungskriterien 
                entsprechen, sei es hinsichtlich Zeit, Kosten oder Komfort. Nutzen Sie unsere innovative Lösung, um ein individuell 
                für Sie passendes Ergebnis zu erhalten.</p>
            <p className="text-base font-extralight text-justify text-lg text-gray-700">In dem ersten Use-Case berechnen und 
                planen wir das Bauvorhaben neuer Trassen zwischen zwei Standorten. In der Regel können wir die Trassen nach 
                verschiedenen Optimierungskriterien, oder einer Kombination der Kriterien, optimal gestalten. </p>
            <p className="text-base font-extralight text-justify text-lg text-gray-700">Alle berechneten Routen sind global 
                optimal, d.h. jede andere Route, die mit konventionellen Methoden geplant wird, wird im besten Fall unseren 
                Routen gleichen oder qualitativ schlechter geplant werden.</p>    
                
                <div className="text-xl">
                <p className="text-base font-extralight text-justify text-lg text-gray-700">
                Für diesen Fall werden folgende Optimierungskriterien bestimmt:
                </p>
                <ol className="list-decimal pl-6 text-base font-extralight text-justify text-lg text-gray-700">
                    <li className="mb-2">
                    Die Bestimmung einer kosten-minimalen Straße hinsichtlich der Baukosten 
                    </li>
                    <li className="mb-2">
                    Die Bestimmung der kürzesten Straße in Bezug auf die Streckenlänge  
                    </li>
                    <li className="mb-2">
                    Die Bestimmung der angenehmsten Straße bezüglich der Höhenänderungen während der Fahrt. 
                    </li>
                </ol>
                </div>
                <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-start gap-4 md:gap-16 pt-4 pb-2 md:pt-12 md:pb-16">
                    <figure >
                        <img
                            src={cs1}
                            alt="glassfiber 1"
                            width={525}
                            
                        />
                    </figure>
                    <figure>
                        <div class="container-bar-line">
                            <div class="text-sm blue-bar"></div>
                            <div class="text-sm text-blue-400">Cheapest route</div>
                        </div>
                        <img
                            src={cs5}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                        <div class="container-bar-line">
                            <div class="text-sm red-bar"></div>
                            <div class="text-sm text-red-400">Shortest route</div>
                        </div>
                        <img
                            src={cs4}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                        <div class="container-bar-line">
                            <div class="text-sm green-bar"></div>
                            <div class="text-sm text-green-400">Most convenient route</div>
                        </div>
                        <img
                            src={cs6}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                        <div class="container-bar-line">
                            <div class="text-sm yellow-bar"></div>
                            <div class="text-sm text-yellow-400">Existing route</div>
                        </div>
                        <img
                            src={cs7}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                    </figure>
                    <figure>
                    <img
                        src={cs2}
                        alt="glassfiber 1"
                        width={740}
                    />
                    </figure>
                </div> 
                <p className="text-base font-extralight text-justify text-lg text-gray-700">Abbildungen und Tabelle:  <em> Optimierungsszenarien 
                    des Straßenbaus mit den Höhenprofilen hinsichtlich unterschiedlicher Optimierungskriterien. Ein Vergleich 
                    zwischen den optimalen Trassen und einer bestehenden Trasse.</em></p>
            </div>
            <div class="button-container mx-auto">
                <div class="flex justify-between">
                    <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                        <a href="/de/dienstleistungen" className='text-white'>
                            ← Dienstleistungen
                        </a>
                    </button>
                    <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                        <a href="/de/casestudy2" className='text-white'>
                        Energienetzwerke →
                        </a>
                    </button>
                </div>
            </div>

            <AppFooter/>
        </>
    );
}
export default Appcasestudy1de;
