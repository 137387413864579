import React from 'react';
import AppHeaderde from '../de_header';
import AppFooterde from '../de_footer';

function ProjectCard({ imgSrc, imgAlt, title, description, bgColor, link }) {
  return (
    <div className="content-container">
    <article className="flex flex-col md:flex-row mb-12 md:mr-8 md:mb-8 lg:mr-16 lg:mb-16">
    <div className="w-full md:w-1/4 pb-4 flex items-center justify-center">
  <div className="w-48 h-48 md:w-40 md:h-40 rounded-full overflow-hidden">
    <a href={link}>
      <img src={imgSrc} alt={imgAlt} className="w-full object-cover" style={{ marginBottom: '16px' }} />
    </a>
  </div>
</div>

      <div className={`w-full md:w-3/4`}>
        
          <h1 className="mt-0 text-3xl break-words">{title}</h1>

          <p className="text-base font-extralight text-justify text-lg text-gray-700">{description}</p>
          <a href={link} className="text-sky-600 inline-block hover:text-red-500">mehr lesen →</a>
        
      </div>
    </article>
  </div>
  );
}

function AppDienstleistungen() {
  const projectsData = [
    {
      imgSrc: require("../../assets/images/Firefly give me route between two cities mapview 82178.jpg"),
      link: "/de/casestudy1",
      imgAlt: "Routenplanung",
      title: "Routenplanung",
      description: "Die Planung von Straßen- und Schienennetzen war bisher größtenteils mit Schätzungen und auf Erfahrung basierenden Annahmen verbunden. Diese Vorgehensweisen sind kostspielig, zeitaufwendig und abhängig von verschiedenen Entscheidungsträgern. Mit CONTRA bieten wir Ihnen die Möglichkeit, maßgeschneiderte Straßen- und Schienenwege zu berechnen, die Ihren spezifischen Optimierungskriterien entsprechen, sei es hinsichtlich Zeit, Kosten oder Komfort. Nutzen Sie unsere innovative Lösung, um ein individuell für Sie passendes Ergebnis zu erhalten.",
    },
    {
      imgSrc: require("../../assets/images/Firefly optical fiber netwok 59557.jpg"),
      link: "/de/casestudy2",
      imgAlt: "Energienetzwerke",
      title: "Energienetzwerke",
      description: "CONTRA ist in der Lage, optimale Energie- und Breitbandnetze für umfangreiche Gebiete mit zahlreichen Gebäuden und Haushalten zu berechnen. Dazu gehören Glasfaser-, Strom-, Gas- und Wasserleitungen. Unsere Algorithmen können sowohl die kostengünstigste als auch die kleinste Netzwerklösung in Bezug auf Verlegungskosten und Netzlänge ermitteln. Wir sorgen für eine effiziente und nachhaltige Energieversorgung.",
    },
    {
      imgSrc: require("../../assets/images/Firefly telecomunication network 43440.jpg"),
      link: "/de/casestudy3",
      imgAlt: "Telekommunikation",
      title: "Telekommunikation",
      description: "Im Bereich Telekommunikation bestimmen unsere Algorithmen den idealen Standort für Telekommunikationsantennen, um die Abdeckung eines geografischen Gebiets zu maximieren und gleichzeitig die Gesamtkosten zu minimieren. Unsere Berechnungen berücksichtigen alle relevanten Faktoren, wie Topographie und Naturschutzgebiete, um eine optimale Lösung zu gewährleisten. Mit CONTRA schaffen Sie die Grundlage für eine umfassende und kosteneffiziente Kommunikationsinfrastruktur.",
    },
    {
      imgSrc: require("../../assets/images/Firefly shipping route network 43440.jpg"),
      link: "/de/casestudy4",
      imgAlt: "Seerouten",
      title: "Seerouten",
      description: "Die Bedingungen auf dem Wasser ändern sich ständig. Dynamische Wasserströmungen, Windrichtungen und Wellenhöhen beeinflussen die Fahrgeschwindigkeit von Schiffen erheblich. Unsere Software nutzt Echtzeitdaten, um Routen mit minimalen Verbrauchskosten und Reisezeiten zu berechnen. Durch den Einsatz multikriterieller Optimierung finden wir die ideale Route auf hoher See. Mit CONTRA steuern Sie Schiffe auf effiziente Weise durch die Gewässer.",
    },
    // Add more projects as needed
  ];

  return (
    <>
      <header id="header">
        <AppHeaderde />
      </header>

      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
            <h1 className='text-white text-center font-bold pt-5 m-0'>Unsere Dienstleistungen</h1>
            </div>
          </section>
        </div>
      </div>

      
        {projectsData.map((project, index) => {
          return <ProjectCard key={index} {...project} />;
        })}
      <div class="button-container mx-auto">
          <div class="flex justify-between">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/de/was_ist_contra" className='text-white'>
                      ← Über CONTRA
                  </a>
              </button>
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/de/casestudy1" className='text-white'>
                  Routenplanung →
                  </a>
              </button>
          </div>
      </div>

      <AppFooterde />
    </>
  );
}

export default AppDienstleistungen;


