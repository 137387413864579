import React from 'react';
import AppHeader from '../header';
import AppFooter from '../footer';

function ProjectCard({ imgSrc, imgAlt, title, description, bgColor, link }) {
  return (
    <div className="content-container">
    <article className="flex flex-col md:flex-row mb-12 md:mr-8 md:mb-8 lg:mr-16 lg:mb-16">
    <div className="w-full md:w-1/4 pb-4 flex items-center justify-center">
      <div className="w-48 h-48 md:w-40 md:h-40 rounded-full overflow-hidden">
        <a href={link}>
          <img src={imgSrc} alt={imgAlt} className="w-full object-cover" style={{ marginBottom: '16px' }} />
        </a>
      </div>
    </div>

      <div className={`w-full md:w-3/4`}>
        
          <h1 className="mt-0 text-3xl break-words">{title}</h1>

          <p className="text-base font-extralight text-justify text-lg text-gray-700">{description}</p>
          <a href={link} className="text-sky-600 inline-block hover:text-red-500">Read more →</a>
        
      </div>
    </article>
  </div>
  );
}

function AppServices() {
  const projectsData = [
    {
      imgSrc: require("../../assets/images/Firefly give me route between two cities mapview 82178.jpg"),
      link: "/en/casestudy1",
      imgAlt: "Route Planning",
      title: "Route Planning",
      description: "Planning of the construction of roads and railways are currently insufficient. The results are based on estimations and experience, they are rather expensive, take a lot of time and depend on different decision makers. \n With CONTRA we can calculate optimal roads and railways depending on your optimization criteria, such as time and cost.",
      bgColor: "bg-red",
    },
    {
      imgSrc: require("../../assets/images/Firefly optical fiber netwok 59557.jpg"),
      link: "/en/casestudy2",
      imgAlt: "Energy Network",
      title: "Energy Network",
      description: "CONTRA can calculate optimal energy and broadband networks to cover large areas with any number of households. This includes fiber optic, electricity and gas networks as well as water pipelines. Our algorithms can calculate both the cheapest and the smallest network in terms of network laying costs and network length.",
      bgColor: "bg-pale-purple",
    },
    {
      imgSrc: require("../../assets/images/Firefly telecomunication network 43440.jpg"),
      link: "/en/casestudy3",
      imgAlt: "Telecommunication",
      title: "Telecommunication",
      description: "In the case of telecommunication, we are able to determine the ideal location for tele-communication antennas to maximize coverage of a geographic area and simultaneously minimize overall costs. During the calculation all kind of restrictions are taken into account, such as the topography of an area or  protected areas.",
      bgColor: "bg-pale-purple",
    },
    {
      imgSrc: require("../../assets/images/Firefly shipping route network 43440.jpg"),
      link: "/en/casestudy4",
      imgAlt: "Sea Routes",
      title: "Sea Routes",
      description: "The direction and intensity of water currents, winds and height of waves change dynamically. They have impacts on the sailing speed of ships. Our software uses real-time data to plan routes with minimal consumption costs and travel time. Furthermore, we apply multi-criteria optimization for optimal route finding in the sea.",
      bgColor: "bg-pale-purple",
    },
    // Add more projects as needed
  ];

  return (
    <>
      <header id="header">
        <AppHeader />
      </header>

      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
            <h1 className='text-white text-center font-bold pt-5 m-0'>Services</h1>
            </div>
          </section>
        </div>
      </div>

      
        {projectsData.map((project, index) => {
          return <ProjectCard key={index} {...project} />;
        })}
      <div class="button-container mx-auto">
          <div class="flex justify-between">
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/whatiscontra" className='text-white'>
                      ← About CONTRA
                  </a>
              </button>
              <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                  <a href="/en/casestudy1" className='text-white'>
                    Route Planning →
                  </a>
              </button>
          </div>
      </div>
      <AppFooter />
    </>
  );
}

export default AppServices;


