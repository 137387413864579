
import React from 'react';
import DankeHeader from './dankeheader.js';
import AppFooterde from './de_footer.js';

function Appimpressionde() {
  return (
    <>
      <header id="header">
        <DankeHeader/>
      </header> 
        
        
       
  
      <main className="flex flex-col h-full w-full flex-1">
      <div className="fdc-content-layout bg-white text-light-grey">
        <section>
        <h1 className=" text-4xl lg:text-6xl font-bold mb-20" id="Impressum">
        Impressum
          </h1>
          <p className="text-lg lg:text-2xl font-extralight ">
            CONTRA UG<br />
            Holstenhofweg 85<br />
            20359 Hamburg<br />
            Germany
          </p>
          <p className="text-lg lg:text-2xl font-extralight ">
            <a
              className="font-serif no-underline hover:text-red"
              href="mailto:info@contra-infra.com"
              target="_blank"
              rel="noopener"
            >
              info@contra-infra.com&nbsp;→
            </a>
            <br />
            +49 40 6541 3880<br />
            +49 40 6541 3879<br />
          </p>
          <p className="text-lg lg:text-2xl font-extralight ">
          Geschäftsführung: Dr. Fawad Zazai<br />
            
          </p>
          <p className="text-lg lg:text-2xl font-extralight ">
          HRB 175184 Amtsgericht Hamburg<br />
          St.-Nr.: 44/712/00617

          </p>
          <h2 className="text-4xl lg:text-5xl mt-12 mb-4 font-bold " id="Haftungsausschluss">Haftungsausschluss</h2>
          <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Liability for content">Liability for content</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der 
            Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf 
            diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht 
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine 
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen 
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten 
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
          <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Urheberrecht">Urheberrecht</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, 
            Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung 
            des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden 
            Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen 
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
          <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Haftung für Links">Haftung für Links</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese 
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der 
            Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige 
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne 
            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
        </section>
        
      </div>
    </main>
    <AppFooterde/>
    </>
  );
};

export default Appimpressionde;