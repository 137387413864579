import AppFooter from '../footer';
import AppHeader from '../header';
import RouteA_2 from '../../../src/assets/images/RouteA_2.png';
import './casestudy.css';



const maritimedata = [
    { data: "Speed in kn", shortestRoute: "5.55", fastestRoute: "5.55", saving: "" },
    { data: "Distance in km", shortestRoute: "5,675.19", fastestRoute: "6,021.13", saving: "-345.94" },
    { data: "Time in hour", shortestRoute: "192.33", fastestRoute: "168.24", saving: "24.09" },
    { data: "Fuel in gallons", shortestRoute: "457,278.38", fastestRoute: "399,987", saving: "57,291.38" },
    { data: "Fuel cost in USD", shortestRoute: "1,557,888", fastestRoute: "1,362,720", saving: "195,168" },
    { data: "Time, fuel and cost saving", shortestRoute: "", fastestRoute: "", saving: "12.53%" },
  ];
  
  console.log(maritimedata);
  ;



function Appcasestudy4() {
    /* const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 815); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial screen size

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); */

    return (
        <>
            <header id="header">
                <AppHeader/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Sea Routes -  Maritime</h1>
                    </div>
                </section>
                </div>
            </div>
            
            <div className="content-container">
                
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">
                    The direction and intensity of water currents, winds and height of waves change dynamically. They have impacts on the sailing speed of ships. 
                    Our software uses real-time data to plan routes with minimal consumption costs and travel time. Furthermore, we apply multi-criteria optimization for optimal route finding in the sea.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">
                        CONTRA Company stands as a pioneering force in sea route optimization, deploying cutting-edge solutions that revolutionize maritime operations. 
                        At the core of CONTRA's capabilities is its adept use of GRIB data, a critical component in calculating the most efficient and cost-effective sea routes. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">
                        With precision-driven algorithms and data-driven strategies, CONTRA excels in computing sea routes that are not only the shortest but also the fastest. 
                        By harnessing the power of GRIB data, they can factor in crucial maritime information, including vessel speed, sea conditions, and potential obstacles. 
                        This comprehensive approach ensures that cargo vessels are guided along routes that guarantee maximum efficiency.
                    </p>                   
                         
                    <p className="text-base font-extralight text-justify text-lg text-gray-700" >CONTRA's impact on the industry is profound. 
                    Their meticulous planning leads to impressive time savings of 24.09 hours, offering companies greater flexibility in meeting tight schedules. In addition, 
                    the extra distance typically incurred due to conventional route planning becomes virtually negligible. 
                    Furthermore, CONTRA's commitment to optimizing sea routes results in a remarkable 12.53% reduction in fuel consumption, delivering both environmental sustainability and significant cost savings for shipping companies.
                    </p>
                    
                    <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-center gap-8 md:gap-16 pt-4 pb-1 md:pt-12 md:pb-16">
                        <figure>
                            <img
                            src={RouteA_2}
                            alt="glassfiber 1"
                            width={950}
                            height={333}
                            
                            />
                        </figure>
                    </div>
                    {/* {isLargeScreen && (
                        <div className="table-container">
                            <table className="horizontal-table">
                                <thead>
                                <tr>
                                    <th className="bg-black text-white text-center">Data</th>
                                    {maritimedata.map((val, key) => (
                                    <td key={key} className="text-center">{val.data}</td>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th className="bg-black text-red-500 text-center">CONTRA Route</th>
                                    {maritimedata.map((val, key) => (
                                    <td key={key} className='text-center text-red-500'>{val.fastestRoute}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th className="bg-black text-yellow-500 text-center">Shortest Route</th>
                                    {maritimedata.map((val, key) => (
                                    <td key={key} className='text-center text-yellow-500'>{val.shortestRoute}</td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    )} */}
                        <div className="table-container">
                        <div className="table-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="bg-blue-200 text-sky-700 text-left" style={{ width: '40%' }}>Data</th>
                                <th className="bg-blue-200 text-sky-700 text-right">Shortest Route</th>
                                <th className="bg-blue-200 text-sky-700 text-right">CONTRA Route</th>
                                <th className="bg-blue-200 text-sky-700 text-right">Saving</th>
                                </tr>
                            </thead>
                            <tbody>
                                {maritimedata.map((val, key) => (
                                <tr key={key}>
                                    <td className='text-left' style={{ width: '40%', color: val.data.includes('Time, fuel and cost saving') ? 'black' : '', fontWeight: val.data.includes('Time, Fuel and cost saving') ? 'bold' : '' }}>{val.data}</td>

                                    <td className='text-right'>{val.shortestRoute}</td>
                                    <td className='text-right'>{val.fastestRoute}</td>
                                    <td className='text-right' style={{ color: val.saving.includes('%') ? 'black' : parseFloat(val.saving) < 0 ? 'red' : 'green', fontWeight: val.saving.includes('%') ? 'bold' : 'normal' }}>{val.saving}</td>
                                    </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                    <p className="text-base font-extralight pt-2 text-justify text-lg text-gray-700 text-center">
                        Figure and Table: <em>Comparison of a directed (shortest) maritime route in yellow with the optimal maritime route in red computed by CONTRA from the US Coast to Near European Waters with Ocean Current (in White) in the Atlantic Ocean.</em>
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700" >As a testament to their prowess, the reduction in fuel costs alone amounts to over $195,000 for the shorter route. 
                    CONTRA's innovative use of GRIB data underlines their dedication to transforming the maritime industry, paving the way for a more efficient and environmentally conscious future in global shipping.
                    </p>
            </div>
            <div class="button-container mx-auto">
                <div class="flex justify-between">
                    <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                        <a href="/en/casestudy3" className='text-white'>
                            ← Telecommunication
                        </a>
                    </button>
                    <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                        <a href="/en/benefits" className='text-white'>
                            Your Benefits →
                        </a>
                    </button>
                </div>
            </div>
            <AppFooter/>
        </>
    );
}
export default Appcasestudy4;
