import whitelogo from '../assets/images/white_logo.png';
import logo from '../assets/images/logo1.png';
import de from '../assets/images/de.png';
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';

const paths = ['/en/services', '/en/casestudy1', '/en/casestudy2', '/en/casestudy3', '/en/casestudy4'];

const pageMapping = {
    '/': '/de',
    '/en/Whatiscontra': '/de/was_ist_contra',
    '/en/services': '/de/dienstleistungen',
    '/en/casestudy1': '/de/casestudy1',
    '/en/casestudy2': '/de/casestudy2',
    '/en/casestudy3': '/de/casestudy3',
    '/en/casestudy4': '/de/casestudy4',
    '/en/benefits': '/de/vorteile',
    '/en/team': '/de/team',
    '/en/contact': '/de/kontakt',
    '/en/impression': '/de/impressum',
    '/en/privacypolicy': '/de/datenschutz',

};

export default function ThankyouHeader() {
    const [navbar, setNavbar] = useState(false);
    const [isHomePage, setIsHomePage] = useState(window.location.pathname === '/'); // Add this line

    const changeBackground = () => {
        if(window.scrollY >= 50) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', changeBackground);

        const handlePathChange = () => { // Add this block
            setIsHomePage(window.location.pathname === '/');
        };
        window.addEventListener('popstate', handlePathChange);

        return () => {
            window.removeEventListener('scroll', changeBackground);
            window.removeEventListener('popstate', handlePathChange); // Add this line
        };
    }, []);

    return (
        <Navbar expand="lg" className={`${navbar ? 'active' : isHomePage ? 'home' : ''}`}>

            <Container>
                <Navbar.Brand href="/">
                    <div>
                        <img src={logo} alt ="contra" className='logo-container logo-change'/>
                    </div>
                </Navbar.Brand>



                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav  className="me-auto" >
                    <Nav.Link className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/' ? '' : 'hover:border-b'}`} href="/" ><span className={`${!navbar ? 'border-black ' : 'border-black'} ${location.pathname === '/' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Home</span></Nav.Link>
                        <Nav.Link className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/Whatiscontra' ? '' : 'hover:border-b'}`} href="/en/Whatiscontra"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/Whatiscontra' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>What is CONTRA?</span></Nav.Link>  
                        <NavDropdown title={<span className={`dropdown-title ${!navbar ? 'text-black border-black' : 'text-black border-black'} ${paths.includes(location.pathname) ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Our Services</span>} id="nav-dropdown">
                            <NavDropdown.Item className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/services' ? '' : 'hover:border-b'}`} href="/en/services"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/services' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Services</span></NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/casestudy1' ? '' : 'hover:border-b'}`} href="/en/casestudy1"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/casestudy1' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Road Planning</span></NavDropdown.Item>
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/casestudy2' ? '' : 'hover:border-b'}`} href="/en/casestudy2"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/casestudy2' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Fiber Optic Network</span></NavDropdown.Item>
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/casestudy3' ? '' : 'hover:border-b'}`} href="/en/casestudy3"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/casestudy3' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Telecommunication</span></NavDropdown.Item>
                            <NavDropdown.Item  className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/casestudy4' ? '' : 'hover:border-b'}`} href="/en/casestudy4"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/casestudy4' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Shipping</span></NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/benefits' ? '' : 'hover:border-b'}`} href="/en/benefits"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/benefits' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Your Benefits</span></Nav.Link>
                        <Nav.Link className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/team' ? '' : 'hover:border-b'}`} href="/en/team"><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/team' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Team</span></Nav.Link>
                        <Nav.Link className={`${!navbar ? 'text-black' : 'text-black'} ${location.pathname === '/en/contact' ? '' : 'hover:border-b'}`} href="/en/contact" ><span className={`${!navbar ? 'border-black' : 'border-black'} ${location.pathname === '/en/contact' ? 'border-black border-b-2 ' : 'hover:border-black hover:border-b'}`}>Contact</span></Nav.Link>
                        <Nav.Link href={pageMapping[location.pathname] || '/de'} className="flag-image">
                            <img
                            src={de}
                            alt="Flag"
                            className="flag-image"
                            />
                        </Nav.Link> 
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>


    )
}
