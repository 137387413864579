import AppFooter from '../footer';
import AppHeader from '../header';
import React from 'react';
import cs1 from '../../../src/assets/images/cs_1_1.png';
import cs2 from '../../../src/assets/images/cs_1_2.png';
import cs4 from '../../../src/assets/images/Shortest_Badghis.png';
import cs5 from '../../../src/assets/images/Cheapest_Badghis.png';
import cs6 from '../../../src/assets/images/Convenient_Badghis.png';
import cs7 from '../../../src/assets/images/Existing_Badghis.png';

import './casestudy.css';

function Appcasestudy1() {
    return (
        <>
            <header id="header">
                <AppHeader/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Route Planning</h1>
                    </div>
                </section>
                </div>
            </div>
            
            <div className="content-container">
                
            <p className="text-base font-extralight text-justify text-lg text-gray-700">Planning of the construction of roads and railways are 
                currently insufficient. The results are based on estimations and experience, they are rather expensive, take a lot of time and 
                depend on different decision makers. With CONTRA we can calculate optimal roads and railways depending on your optimization 
                criteria, such as time and cost.</p>
            <p className="text-base font-extralight text-justify text-lg text-gray-700">In the first use-case we calculate and plan the 
                construction of new routes between two locations. In general, we can optimize the routes according to different optimization 
                criteria, or a combination of them.</p>
            <p className="text-base font-extralight text-justify text-lg text-gray-700">All calculated routes are globally optimal, 
                which means that any other route planned by conventional methods will either be equal to our routes or most likely 
                be planned in a qualitatively worse way.</p>    
                
                <div className="text-xl">
                <p className="text-base font-extralight text-justify text-lg text-gray-700">
                    The following optimization criteria are determined for this case:
                </p>
                <ol className="list-decimal pl-6 text-base font-extralight text-justify text-lg text-gray-700">
                    <li className="mb-2">
                    The determination of a cost-minimal road in terms of construction costs
                    </li>
                    <li className="mb-2">
                    The determination of the shortest road concerning the length of the route
                    </li>
                    <li className="mb-2">
                    The determination of the most convenient road regarding the elevation changes during the ride
                    </li>
                </ol>
                </div>
                <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-start gap-4 md:gap-16 pt-4 pb-2 md:pt-12 md:pb-16">
                    <figure >
                        <img
                            src={cs1}
                            alt="glassfiber 1"
                            width={525}
                            
                        />
                    </figure>
                    <figure>
                        <div class="container-bar-line">
                            <div class="text-sm blue-bar"></div>
                            <div class="text-sm text-blue-400">Cheapest route</div>
                        </div>
                        <img
                            src={cs5}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                        <div class="container-bar-line">
                            <div class="text-sm red-bar"></div>
                            <div class="text-sm text-red-400">Shortest route</div>
                        </div>
                        <img
                            src={cs4}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                        <div class="container-bar-line">
                            <div class="text-sm green-bar"></div>
                            <div class="text-sm text-green-400">Most convenient route</div>
                        </div>
                        <img
                            src={cs6}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                        <div class="container-bar-line">
                            <div class="text-sm yellow-bar"></div>
                            <div class="text-sm text-yellow-400">Existing route</div>
                        </div>
                        <img
                            src={cs7}
                            alt="optical fiber 2"
                            width={360}
                            
                        />
                    </figure>
                    <figure>
                    <img
                        src={cs2}
                        alt="glassfiber 1"
                        width={740}
                    />
                    </figure>
                </div>    
                <p className="text-base font-extralight text-justify text-lg text-gray-700">Figures and Table:  <em> Optimization 
                    scenarios of the road construction with elevation profiles regarding different optimization criteria. 
                    A comparison between optimal routes calculated by CONTRA and an existing route.</em>
                </p>                   
            </div>
            <div class="button-container mx-auto">
                <div class="flex justify-between">
                    <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                        <a href="/en/services" className='text-white'>
                            ← Our Services
                        </a>
                    </button>
                    <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                        <a href="/en/casestudy2" className='text-white'>
                            Energy Network →
                        </a>
                    </button>
                </div>
            </div>

            <AppFooter/>
        </>
    );
}
export default Appcasestudy1;
