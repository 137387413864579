import AppHeaderde from '../de_header';
import AppFooterde from '../de_footer';
import img1 from '../../../src/assets/images/Firefly a lot of people with desks working on algorithm and doing high performance work; professiona.jpg';
import animation from '../../../src/assets/images/animation.gif';

function AppAboutde() {

  return (
    <>
      <header id="header">
        <AppHeaderde />
      </header>
      <div className="bg-black">
        <div className="mx-auto py-20 px-3">
          <section>
            <div className="py-5 md:py-24">
            <h1 className='text-white text-center font-bold pt-5 m-0'>Was ist CONTRA?</h1>
            </div>
          </section>
        </div>
      </div>

      <div className="content-container md:px-20">
        {/* First Row */}
        <div className="flex flex-col md:flex-row items-start md:items-start justify-between py-8">
          <div className="md:w-1/2">
            <p className="text-base font-extralight text-lg text-justify text-gray-700">
                CONTRA, ein Akronym für <em>Computing an Optimal Network of Transit Routes 
                through Mathematical Algorithms</em>, ist unsere innovative Software, 
                die gezielt für die strategische Infrastrukturplanung entwickelt wurde.
              </p>
              <p className="text-base font-extralight text-lg text-justify text-gray-700">
                Mit CONTRA können Trassen und Netzwerke, abhängig von Planungsumfang, innerhalb weniger 
                Stunden oder weniger Tage berechnet werden. Diese bemerkenswert kurze Planungszeit ist das 
                Ergebnis fortschrittlicher Optimierungsmethoden und intelligenter Computeralgorithmen.
              </p>
              <p className="text-base font-extralight text-lg text-justify text-gray-700">
                In Bezug auf die Trassenplanung ermöglicht unsere Software die Berechnung und Planung neuer intermodaler 
                Routen zwischen verschiedenen Standorten, sei es innerhalb eines Landes oder grenzüberschreitend. Diese Routen 
                können aus unterschiedlichen Infrastruktursegmenten wie Straßen, Eisenbahnen, Pipelines, Tunneln und Brücken bestehen. 
                Hierbei nutzen wir vielfältige GIS-Daten, um Bodenbeschaffenheit, Umweltbedingungen und topografische Höhendaten zu analysieren und auszuwerten. Das Ergebnis, das CONTRA liefert, 
                beinhaltet eine umfassende Übersicht der berechneten Trasse, einschließlich Trassenverlauf, Trassenlänge, Höhenprofil und Baukosten der Trasse.
              </p>
              
              </div>
              <div className="md:w-1/2 flex justify-center md:justify-end mt-5 md:mt-0">
                <figure className="flex items-start">
                  <img
                    src={animation}
                    alt="optical fiber 2"
                    className="w-full"
                  />
                </figure>
              </div>
            </div>
            
        {/* Second Row */}
        <div className="flex flex-col md:flex-row items-start md:items-start justify-between py-8 md:flex-row-reverse">
            <div className="md:w-1/2">
              <p className="text-base font-extralight text-lg text-justify text-gray-700">
              Neben unserer herausragenden Trassenplanungsfunktion bietet CONTRA eine Vielzahl weiterer leistungsstarker Features: 
              Unsere Software kann den optimalen Verlauf von Energienetzwerken und Versorgungsleitungen in Gebieten mit beliebig vielen 
              Haushalten planen. Dies umfasst Pipelines, Glasfasernetze, Gasnetze, Stromnetze und Wasserleitungen. Unsere Algorithmen 
              gewährleisten eine nachhaltige und kosteneffiziente Planung der Verlegung dieser Netzwerke. Zusätzlich kann CONTRA die 
              kürzesten Netzwege berechnen, um Material und Energie einzusparen, Abstände zu verringern und somit die Reibung und den 
              Druck zu reduzieren. Das Ergebnis? Weniger CO2-Emissionen und eine grünere Zukunft.</p>
    
              <p className="text-base font-extralight text-lg text-justify text-gray-700">
              Zusätzlich zu der optimalen Trassenplanung und der Ermittlung effizienter Energienetzwerke kann 
              CONTRA ebenfalls eine optimale Standortplanung durchführen, bei dem zahlreiche Parameter berücksichtigt werden: 
              von der Menge an verfügbaren Rohstoffen und Ressourcen über Energieträger bis zur bestehenden Infrastruktur und 
              dem Marktpotenzial. Wir wählen Standorte mit maximalem Absatzpotenzial bei minimalen Transport- und Versorgungskosten 
              aus. Dies führt nicht nur zu Kosteneinsparungen, sondern auch zu einer nachhaltigen Geschäftstätigkeit und verschafft 
              unseren Kunden einen strategischen Wettbewerbsvorteil.</p>
              <p className="text-base font-extralight text-lg text-justify text-gray-700">
              Leistungsfähige Hardware und Hochleistungsrechner werden für effektive Berechnungen und Entwürfe eingesetzt. Unsere 
              Ergebnisse sind kostengünstiger und präziser als herkömmliche Methoden, wodurch Sie Zeit, Kosten und Ressourcen sparen.</p>
              
            </div>
            <div className="md:w-1/2 flex justify-center md:justify-start mt-5 md:mt-0">
              <figure className="flex items-start">
                <img
                  src={img1}
                  alt="glassfiber 1"
                  className="w-full max-w-xl"
                />
              </figure>
            </div>
          </div>
          <p className="text-base font-extralight text-lg text-justify text-gray-700">
              Mit CONTRA erhalten Sie nicht nur eine innovative Lösung für Trassenplanung, sondern auch eine ganzheitliche Plattform 
              für nachhaltige und kosteneffiziente Infrastrukturplanung. Gemeinsam mit Ihnen gestalten wir die Zukunft der Planung neu!</p>
        </div>
        
        <div class="button-container mx-auto">
            <div class="flex justify-end">
                <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                    <a href="/de/dienstleistungen" className='text-white'>
                    Unsere Dienstleistungen →
                    </a>
                </button>
            </div>
        </div>
      <AppFooterde />
    </>
  );
}

export default AppAboutde;
