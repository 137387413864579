import AppFooterde from '../de_footer';
import AppHeaderde from '../de_header';
import React from 'react';
import coverage1 from '../../../src/assets/images/Coverage1.jpg';
import coverage2 from '../../../src/assets/images/Coverage2.jpg';


const data = [
    { net: "existing network", len: "441 m", cost: "36.979 €" },
    { net: "CONTRA network", len: "466 m", cost: "22.934 €" },
    { net: "savings (absolut) ", len: "-25 m" , cost: "14.045 €" },
]

function Appcasestudy3de() {
    return (
        <>
            <header id="header">
                <AppHeaderde/>
            </header>
            <div className="bg-black">
            <div className="mx-auto py-20 px-3">
                <section>
                    <div className="py-5 md:py-24">
                    <h1 className='text-white text-center font-bold pt-5 m-0'>Telekommunikation</h1>
                    </div>
                </section>
                </div>
            </div>
            
            <div className="content-container">
                
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Im Bereich Telekommunikation bestimmen unsere Algorithmen 
                        den idealen Standort für Telekommunikationsantennen, um die Abdeckung eines geografischen Gebiets zu maximieren und gleichzeitig die 
                        Gesamtkosten zu minimieren. Unsere Berechnungen berücksichtigen alle relevanten Faktoren, wie Topographie und Naturschutzgebiete, 
                        um eine optimale Lösung zu gewährleisten. Mit CONTRA schaffen Sie die Grundlage für eine umfassende und kosteneffiziente Kommunikationsinfrastruktur.
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Für die optimale Planung von Telekommunikationsantennen 
                    können die intelligenten Algorithmen CONTRAs ebenfalls genutzt werden. Um eine flächendeckende Versorgung zu gewährleisten, 
                    werden in zellularen Mobilfunknetzen viele Stationen benötigt und diese müssen an unterschiedlichen Standorten errichtet werden. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Antennenmodelle mit unterschiedlicher Leistung oder 
                    Signalabdeckung, wie bei 5G-Atennen, LTE, oder GSM/2G, können unterschiedliche Kosten verursachen. Die Standorte der Antennen 
                    werden aktuell durch Abschätzungsmethoden bestimmt und die Signalausbreitung wird durch Ray-tracing-Verfahren am Computer simuliert, 
                    um die Qualität der Signale zu überprüfen. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Im Vergleich zu der gängigen Vorgehensweise, ist es 
                    anhand unserer Software möglich, optimale Standorte für den Bau von Antennen in einem ausgewählten Gebiet zu bestimmen, um einerseits 
                    die Signalabdeckung zu maximieren und andererseits die Gesamtkosten der Antennen zu minimieren. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Unsere Software analysiert ein geografisches Gebiet 
                    Punkt für Punkt, bestimmt danach die optimale Verteilung der Antennenstandorte über ein ausgewähltes Gebiet und wählt die für eine 
                    gute Signalabdeckung erforderliche minimale Anzahl an Antennen aus. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Das Ergebnis ist eine Zusammenfassung aller Daten, 
                    die die Antennenstandorte, die Baukosten, die Anzahl der Antennen und den Bereich der Signalabdeckung beinhaltet.<br/><br/>
                    </p>
                    
                    
                    <hr />
                
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Beispiel: Die rote Markierung umfasst den Bereich, 
                    in dem die Antennen (rote Punkte) gebaut werden sollen. Die Signalüberdeckung einer Antenne wird hier als eine Kreisfläche dargestellt. 
                    Die gelben Überdeckungen sind Antennen mit geringerer Reichweite und die blauen Überdeckungen stellen Antennen mit höherer Reichweite dar. 
                    </p>
                    <p className="text-base font-extralight text-justify text-lg text-gray-700">Nachfolgend wird in der rechten Abbildung eine optimale 
                    Verteilung der Antennenstandorte  durch CONTRA visualisiert. Bei dieser Planung wurden zwei Antennen mit geringerer Reichweite gespart, 
                    trotz ausreichender Überdeckung. In unserem Fall wurden 20% weniger Antennen eingesetzt und dadurch an Kosten gespart.
                    </p>
                    <div className=" flex flex-col md:flex-row justify-center items-center md:place-items-start gap-8 md:gap-16 pt-4  md:pt-12 ">
                        <figure className="mx-auto w-full text-center">
                            <img
                                src={coverage1}  // Replace with the path to your blank image
                                alt="telecommunicaton image"  // Provide a suitable alt text
                                width={500}
                                height={279.42}
                                className="mx-auto bg-black"
                            />
                            <p className="text-base font-extralight pt-2 pb-2 text-justify text-lg text-gray-700 text-center">Abbildung 1:  <em>Manuelle Planung eines Telekommunikationsnetzwerks.</em></p>

                        </figure>
                        
                        <figure className="mx-auto w-full text-center">
                        <img
                            src={coverage2}  // Replace with the path to your blank image
                            alt="telecommunicaton image"  // Provide a suitable alt text
                            width={500}
                            height={279.42}
                            className="mx-auto bg-black"
                        />
                        <p className=" text-base font-extralight pt-2 pb-2 text-justify text-lg text-gray-700 text-center">Abbildung 2:  <em>Optimale Planung eines Telekommunikationsnetzwerks durch CONTRA.</em></p>
                        </figure>
                    
                    </div>
                </div>
                <div class="button-container mx-auto">
                    <div class="flex justify-between">
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/de/casestudy2" className='text-white'>
                                ← Energienetzwerke
                            </a>
                        </button>
                        <button class="bg-sky-800 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full">
                            <a href="/de/casestudy4" className='text-white'>
                            Seerouten →
                            </a>
                        </button>
                    </div>
                </div>
            
            <AppFooterde/>
        </>
    );
}
export default Appcasestudy3de;
