import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/Firefly The new dimension of infrastructure planning for industry construction, aerial view to city .jpg'),
    title: 'Die neue Dimension der Infrastrukturplanung: CONTRA',
    description: 'Mit unserer neuen und innovativen Software CONTRA optimieren und digitalisieren wir weltweit Infrastrukturprojekte unter Berücksichtigung von Zeit, Kosten und Umweltauswirkungen!',
    link: '/en/casestudy2'
  },
  {
    id: 2,
    image: require('../assets/images/road.jpg'),
    title: 'Routenplanung',
    description: 'Mit unserem intelligenten, KI-basierten Programm können wir Straßen und Schienennetze wissenschaftlich, objektiv und optimal planen, wobei wir die Baukosten, die Streckenlänge und den Komfort berücksichtigen.',
    link: '/en/casestudy1'
  },
  {
    id: 3,
    image: require('../assets/images/fiber.jpg'),
    title: 'Energienetzwerke',
    description: 'Wir können optimale Energie- und Breitbandsysteme berechnen, um große Gebiete mit einer beliebigen Anzahl von Haushalten abzudecken. Dazu gehören Glasfasernetze, Stromnetze, Gasnetze und Wasserpipelines.',
    link: '/en/casestudy2'
  },
  {
    id: 4,
    image: require('../assets/images/tele.jpg'),
    title: 'Telekommunikation',
    description: 'Im Fall des Telekommunikationssektors sind wir in der Lage, den idealen Standort für Telekommunikationsantennen zu bestimmen, um die Netzabdeckung in einem geografischen Gebiet zu maximieren und gleichzeitig die Gesamtkosten zu minimieren.',
    link: '/en/casestudy3'
  },
  {
    id: 5,
    image: require('../assets/images/shipping.jpg'),
    title: 'Seerouten',
    description: 'CONTRA kann mit Hilfe von GRIB-Daten optimale Schifffahrtsrouten zur Reduzierung von Kosten, Zeit und Treibstoffverbrauch berechnen.',
    link: '/en/casestudy3'
  },
  /* {
    id: 6,
    image: require('../assets/images/railway.jpg'),
    title: 'Standortplanung',
    description: 'Die Algorithmen der Software ermöglichen es uns, die optimalen Standorte für Lagerhäuser, Industrien, Geschäfte oder Minen in einem bestimmten Gebiet zu berechnen, um Ressourcen optimal zu nutzen und einen effizienten Transport zu gewährleisten.',
    link: 'ankitvaghasiya.com'
  }, */
  
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;