import React from 'react';
import ThankyouHeader from './thankyouheader';
import AppFooter from './footer';
import './privacypolicy.css';

const AppPrivacyPolicy = () => {
  return (
    <>
    <header id="header">
            <ThankyouHeader/>
    </header> 
    <main className="flex flex-col h-full w-full flex-1">
      <div className="fdc-content-layout bg-white text-light-grey">
        <section>
            <h1 className=" text-4xl lg:text-6xl font-bold mb-20" id="privacy-policy">Privacy Policy</h1>
             <p className="text-lg lg:text-2xl font-extralight ">
            What are personal data?<br />
            All data that can be related to you personally, e.g. name, address, e-mail addresses, user behaviour.
            </p>
             <p className="text-lg lg:text-2xl font-extralight ">
            The protection of your personal data (“Data”) is of particular importance to us.
            <br />
            Therefore, we would like to inform you comprehensively about which data we collect from you and for what purpose.
            </p>

          
            <h2 className="text-4xl lg:text-5xl mt-12 mb-4 font-bold " id="when-do-we-process-data-and-for-what-purpose">When do we process data and for what purpose?</h2>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="contact-through-you">contact through you</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
                When you contact us by e-mail, the data you provide will be stored by us in order to process your inquiry or request.
                In doing so, we will at least receive your first and last name and your e-mail address.
            </p>
            <p className="text-lg lg:text-2xl font-extralight ">
                We delete the data collected for the above-mentioned purpose after storage is no longer necessary or restrict processing if there are legal obligations to keep records.
            </p>
            <p className="text-lg lg:text-2xl font-extralight ">
                The legal basis for this data processing is Art. 6 para. 1 sentence 1 lit. b) of the EU Basic Data Protection Regulation (“DSGVO”).
            </p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="google-analytics">Google Analytics</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
                We use Google Analytics for statistical evaluation of the use of our website.
                Details of how we use Google Analytics and how to disable Google Analytics are described below.
            </p>
            <p className="text-lg lg:text-2xl font-extralight ">
                The legal basis for this data processing is Art. 6 para. 1 sentence 1 letter f) DSGVO.
            </p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="No processing of server log data">No processing of server log data</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            It is important to us to process as little data as possible from you.
            Unlike many of the other websites, we do not collect so-called server log files via your browser.<br />
            Server log files include, for example, the browser type and version, the operating system you use, the website from which you visit us (referrer URL), the date and time of access.
            </p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="No Cookies">No Cookies</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            With the exception of Google Analytics, this website does not use cookies.
            </p>
            <h2 className="text-4xl lg:text-5xl mt-12 mb-4 font-bold " id="when-do-we-process-data-and-for-what-purpose">Google Analytics</h2>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="basics">basics</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            Our website uses Google Analytics from Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States of America (“Google”).
            Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site.
            The information generated by the cookie about your use of this website is usually transferred to a Google server in the USA and stored there.<br/>
            </p>
            <p className="text-lg lg:text-2xl font-extralight ">
            We have activated IP anonymisation on this website, therefore your IP address will be shortened by Google within member states of the European Union or in other states which are party to the Agreement on the European Economic Area before.<br/>
            Only in exceptional cases will the full IP address be transferred to a Google server in the USA and shortened there.<br/>
            On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage.<br/>
            The IP address transmitted by your browser within the framework of Google Analytics is not combined with other data from Google.
            </p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="Google Analytics Browser Opt-Out">Google Analytics Browser Opt-Out</h3>
            <p className="text-lg lg:text-2xl font-extralight ">
            You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website.<br/>
            You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) and the processing of this data by Google by downloading and installing the browser plugin available at the following link:<br/>
            <a class="font-bold no-underline hover:text-red" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout?hl=de&nbsp;→</a>
            </p>
            <p className="text-lg lg:text-2xl font-extralight ">
            We have activated IP anonymisation on this website, therefore your IP address will be shortened by Google within member states of the European Union or in other states which are party to the Agreement on the European Economic Area before.<br/>
            Only in exceptional cases will the full IP address be transferred to a Google server in the USA and shortened there.<br/>
            On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage.<br/>
            The IP address transmitted by your browser within the framework of Google Analytics is not combined with other data from Google.
            </p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="ip-anonymization">IP anonymization</h3>
            <p className="text-lg lg:text-2xl font-extralight ">This website uses Google Analytics with the extension <code>_anonymizeIp()</code>.<br/>
            This allows IP addresses to be further processed in a shortened form, thus excluding the possibility of personal references.<br/>
            If the data collected about you contains a personal reference, this is immediately excluded and the data is therefore deleted immediately.
            </p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="purposes-of-data-processing">purposes of data processing</h3>
            <p className="text-lg lg:text-2xl font-extralight ">We use Google Analytics to analyze and regularly improve the use of our website.<br/>We can use the statistics obtained to improve our offer and make it more interesting for you as a user.<br/>
            For the exceptional cases in which data is transferred to the USA, Google has subjected itself to the EU-US Privacy Shield, <br/>
            <a class="font-bold no-underline hover:text-red" href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener">https://www.privacyshield.gov/EU-US-Framework&nbsp;→</a>.</p>
            <h3 className="text-3xl lg:text-4xl mt-12 mb-4 font-bold " id="information-from-google-and-order-processing">Information from Google and order processing</h3>
            <p className="text-lg lg:text-2xl font-extralight ">Third party information:</p>
            <p className="text-lg lg:text-2xl font-extralight ">Google Dublin, Google Ireland Ltd, Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.</p>
            <p className="text-lg lg:text-2xl font-extralight ">User conditions:<br/><a class="font-bold no-underline hover:text-red" href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener">http://www.google.com/analytics/terms/de.html&nbsp;→</a>,</p>
            <p className="text-lg lg:text-2xl font-extralight ">Overview on data protection:<br/><a class="font-bold no-underline hover:text-red" href="http://www.google.com/intl/de/analytics/learn/privacy.html" target="_blank" rel="noopener">http://www.google.com/intl/de/analytics/learn/privacy.html&nbsp;→</a>,</p>
            <p className="text-lg lg:text-2xl font-extralight ">and the privacy policy:<br/><a class="font-bold no-underline hover:text-red" href="http://www.google.de/intl/de/policies/privacy" target="_blank" rel="noopener">http://www.google.de/intl/de/policies/privacy&nbsp;→</a>.</p>
            <h2 className="text-4xl lg:text-5xl mt-12 mb-4 font-bold " id="Verantwortlich">Responsible</h2>
            <p className="text-lg lg:text-2xl font-extralight ">Responsible according to Art. 4 No. 7 DSGVO is</p>
            <p className="text-lg lg:text-2xl font-extralight ">CONTRA UG<br/>Holstenhofweg 85<br/>22043 Hamburg<br/>Germany</p>
            <p className="text-lg lg:text-2xl font-extralight ">Phone: <a href="tel:00494065413880">+49 40 6541 3880</a><br/>E-mail: <a href="mailto:info@contra-infra.com"></a><a class=" no-underline hover:text-red" href="mailto:info@contra-infra.com" target="_blank" rel="noopener">info@contra-infra.com&nbsp;→</a><br/>website: <a class=" no-underline hover:text-red" href="https://www.contra-infra.com" target="_blank" rel="noopener">https://www.contra-infra.com&nbsp;→</a></p>
        </section>
      </div>
    </main>
    <AppFooter/>
    </>
  );
};

export default AppPrivacyPolicy;
